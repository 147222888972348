import "firebase/auth";
import "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  where,
  onSnapshot,
  updateDoc,
  limit,
  deleteField,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { randomId } from "@mui/x-data-grid-generator";
import { getDataFromSheet } from "../google-sheets/google-sheets.utils";
import { isEqual } from "lodash";

const firebaseConfig = {
  apiKey: "AIzaSyBEtVw8grcsxsn2jGq6CBIRgCXmOcdI4iI",
  authDomain: "plas-parking-lot-assistant.firebaseapp.com",
  projectId: "plas-parking-lot-assistant",
  storageBucket: "plas-parking-lot-assistant.appspot.com",
  messagingSenderId: "534241655229",
  appId: "1:534241655229:web:0bd384f6987826df653ad4",
  measurementId: "G-1E5EPF2ENB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export default storage;
const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
// export const signInWithGooglePopup = () => {
//   signInWithPopup(auth, googleProvider).then((result) => {
//     const user = result.user;
//     console.log(user);
//   });
// };
export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);

export const db = getFirestore();

export const createUserDocFromAuth = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userDocRef = doc(db, "users", userAuth.email);
  const userSnapShot = await getDoc(userDocRef);
  console.log(userSnapShot.data());

  if (!userSnapShot.exists()) {
    console.log("Error - should not happen");
    return;
  }
  const companyDocRef = doc(db, "companies", userSnapShot.get("companyName"));
  const companySnapShot = await getDoc(companyDocRef);
  return [userSnapShot, companySnapShot.get("time")];
};

export const createAuthUserWithEmail = async (email, password) => {
  if (!email || !password) return;
  return await createUserWithEmailAndPassword(
    auth,
    email, //.toLowerCase().trim(),
    password
  );
};

export const signInAuthUserWithEmail = async (email, password) => {
  if (!email || !password) return;
  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callBack) =>
  onAuthStateChanged(auth, callBack);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};

export const convertCollectionsSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const { title, items } = doc.data();

    return {
      routeName: encodeURI(title.toLowerCase()),
      id: doc.id,
      title,
      items,
    };
  });

  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[collection.title.toLowerCase()] = collection;
    return accumulator;
  }, {});
};

export const createNewCompany = async (formFields, logo, email) => {
  // if (logo === "") return null;
  if (formFields.length === 0) return null;
  const companyName = formFields.companyName.toLowerCase();
  const dataRef = doc(db, "data", "companies");
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapShot = await getDoc(companyDocRef);
  const q = query(
    collection(db, "parkinglots"),
    where("name", "==", formFields.parkingLotId)
  );
  console.log(formFields.parkingLotId);
  const parkinglotsQuerySnapShot = await getDocs(q);
  const batch = writeBatch(db);
  let plId = "";
  if (parkinglotsQuerySnapShot.empty) {
    // Needs a check to make sure it is good
    const allParkingLots = await getParkingLotsIds();
    plId = `p${randomId().slice(0, 4)}`;
    // plId = `p2341`;
    if (allParkingLots !== null) {
      while (allParkingLots.includes(plId)) plId = `p${randomId().slice(0, 4)}`;
    }
  } else {
    plId = parkinglotsQuerySnapShot.docs[0].id;
  }

  // Creating new company doc
  if (!companySnapShot.exists()) {
    const createdAt = new Date();
    const allCompanies = doc(db, "data", "companies");
    batch.update(allCompanies, {
      ids: arrayUnion(`${companyName}`),
    });

    try {
      batch.update(companyDocRef, {
        company_id: `${companyName}`,
        parking_id: plId,
        community_participation: false,
        logo: `${companyName}.${logo.type.split("/")[1]}`,
        parkings: formFields.hasOwnProperty("parkingsUrl") ? true : false,
        desks: formFields.hasOwnProperty("desksUrl") ? true : false,
        evening_notification: true,
        morning_notification: "8",
        lock: false,
        proximity: false,
        ev_time: "12",
        arrive_time: "9",
        friday: false,
        time: false,
      });
    } catch (error) {
      console.log("error creating Company", error.message);
    }
  } else {
    console.log("company already exists in db");
    return;
  }
  if (parkinglotsQuerySnapShot.empty) {
    //Adding company to parkinglots
    const plRef = doc(db, "parkinglots", plId);
    try {
      batch.update(plRef, {
        // companies: { [`${companyName}`]: parkings.length },
        companies: { [`${companyName}`]: false }, // Set default to false the participation in community
        name: formFields.parkingLotId,
        create_time: "09:30",
      });
    } catch (error) {
      console.log("error creating Company", error.message);
    }
  } else {
    // console.log(parkinglotsQuerySnapShot.docs[0].id);
    const plRef = doc(db, "parkinglots", parkinglotsQuerySnapShot.docs[0].id);
    batch.update(plRef, {
      companies: {
        ...parkinglotsQuerySnapShot.docs[0].data().companies,
        // [`${companyName}`]: parkings.length,
        [`${companyName}`]: false, // Set default to false the participation in community
      },
    });
  }

  // Uploading the new users and parkings data
  // console.log(formFields.usersUrl.split("/")[5]);
  const users = await getDataFromSheet(formFields.usersUrl.split("/")[5]);
  const parkings = await getDataFromSheet(formFields.parkingsUrl.split("/")[5]);
  const desks = await getDataFromSheet(formFields.desksUrl.split("/")[5]);
  await Promise.all(
    users !== null
      ? users.map(async (row) => {
          if (row) {
            await myUpdateDoc("users", companyName, row);
          }
        })
      : null,
    parkings !== null
      ? parkings.map(async (row) => {
          if (row) {
            await myUpdateDoc("parkings", companyName, row);
          }
        })
      : null,
    desks !== null
      ? desks.map(async (row) => {
          if (row) {
            await myUpdateDoc("desks", companyName, row);
          }
        })
      : null
  );

  const userRef = doc(db, "users", email);
  batch.update(userRef, {
    companyName: companyName,
  });
  batch.update(dataRef, {
    companies: arrayUnion(companyName),
  });

  await batch.commit();

  const storageRef = ref(
    storage,
    `logos/${companyName}.${logo.type.split("/")[1]}`
  );

  await uploadBytesResumable(storageRef, logo);
  // const ImageURL = await getDownloadURL(ref(storage, location));
  // return ImageURL;

  console.log("created new company");
  const updatedCompanySnapshot = await getDoc(companyDocRef);
  return updatedCompanySnapshot;
  //   return userDocRef;
};

export const getParkingLotsNames = async () => {
  const plColRef = collection(db, "parkinglots");
  const q = query(plColRef);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No parkinglots`);
    return null;
  }
  const parkinglots = [];
  querySnapshot.docs.map((docSnapshot) => {
    parkinglots.push(docSnapshot.data().name);
  });
  return parkinglots;
};

export const getParkingLotsIds = async () => {
  const plColRef = collection(db, "parkinglots");
  const q = query(plColRef);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No parkinglots`);
    return null;
  }
  const parkinglots = [];
  querySnapshot.docs.map((docSnapshot) => {
    parkinglots.push(docSnapshot.id);
  });
  return parkinglots;
};

export const getCompanyData = async (category, companyName) => {
  if (!companyName || !category) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companyUsers = collection(companyDocRef, `${category}`);
  const q = query(companyUsers);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No ${category}`);
    return [];
  }
  let dataMap = [];
  if (category === "users") {
    dataMap = await Promise.all(
      // Added here await not sure if it is good - if error remove
      querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        const usersDocData = await getDoc(
          doc(db, "users", data.email.toLowerCase())
        );
        if (!usersDocData.exists()) return;
        const toReturn = {
          id: data.email,
          email: data.email,
          name: data.name,
          phone: data.phone,
          cars: data.cars,
          teams: data.hasOwnProperty("teams") ? data.teams : [],
          priority: data.priority,
          role: usersDocData.data()?.role,
          electric: data?.electric,
          arrival_time: data?.arrival_time,
        };
        return toReturn;
      })
    );
  } else if (category === "desks") {
    dataMap = querySnapshot.docs.map((docSnapshot) => {
      const data = docSnapshot.data();
      return {
        id: `${data.level}:${data.desk_id}`,
        desk_id: data.desk_id,
        level: data.level,
        teams: data.hasOwnProperty("teams") ? data.teams : [],
        tags: data.hasOwnProperty("tags") ? data.tags : [],
        priority: data.priority,
      };
    });
    dataMap.sort((a, b) => {
      try {
        if (a.level.length > b.level.length) return 1;
        else if (a.level.length < b.level.length) return -1;
        else {
          if (a.level > b.level) return 1;
          else if (a.level < b.level) return -1;
          else {
            if (a.desk_id.length > b.desk_id.length) return 1;
            else if (a.desk_id.length < b.desk_id.length) return -1;
            else {
              if (a.desk_id > b.desk_id) return 1;
              else if (a.desk_id < b.desk_id) return -1;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      return a.id > b.id ? 1 : -1;
    });
  } else {
    dataMap = querySnapshot.docs.map((docSnapshot) => {
      const data = docSnapshot.data();
      return {
        id: `${data.level}:${data.parking_id}`,
        parking_id: data.parking_id,
        level: data.level,
        double_parking: data.double_parking,
        priority: data.priority,
        electric: data.electric,
      };
    });
    dataMap.sort((a, b) => {
      try {
        if (a.level.length > b.level.length) return 1;
        else if (a.level.length < b.level.length) return -1;
        else {
          if (a.level > b.level) return 1;
          else if (a.level < b.level) return -1;
          else {
            if (a.parking_id.length > b.parking_id.length) return 1;
            else if (a.parking_id.length < b.parking_id.length) return -1;
            else {
              if (a.parking_id > b.parking_id) return 1;
              else if (a.parking_id < b.parking_id) return -1;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      return a.id > b.id ? 1 : -1;
    });
  }
  // console.log(dataMap);
  return dataMap;
};

export const getCompanyTeams = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const docData = await getDoc(companyDocRef);
  if (!docData.exists()) {
    console.log(`company doesnt exist`);
    return;
  }
  var teams = [];
  try {
    teams = docData.get("teams");
  } catch (e) {
    return []; // No teams in company
  }
  return teams;
};

export const getAllUsersBaseInfo = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const reportCol = collection(companyDocRef, `users`);
  const q = query(reportCol);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No users`);
    return;
  }
  const dataMap = querySnapshot.docs.map((docSnapshot) => {
    return {
      email: docSnapshot.id,
      name: docSnapshot.data().name,
      cars: Object.keys(docSnapshot.data()).includes("cars")
        ? docSnapshot.data().cars
        : null,
    };
  });
  // console.log(dataMap);
  return dataMap;
};

export const checkCarNumber = async (companyName, carNumber) => {
  if (!companyName || !carNumber) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companyData = await getDoc(companyDocRef);
  const plDocRef = doc(db, "parkinglots", companyData.data().parking_id);
  const plData = await getDoc(plDocRef);
  let userData = null;
  let userCompany = null;
  await Promise.all(
    Object.keys(plData.data().companies).map(async (company) => {
      if (company === companyName) return false; // Already check users own company
      const companyDoc = doc(db, "companies", company);
      const usersCol = collection(companyDoc, `users`);
      const carsQuery = query(
        usersCol,
        where("cars", "array-contains", carNumber),
        limit(1)
      );
      const carUserDoc = await getDocs(carsQuery);
      if (carUserDoc.docs.length !== 0) {
        userData = carUserDoc.docs[0].data();
        userCompany = company;
        // return true;
      }
      // return false;
    })
  );
  if (userData !== null) {
    return { ...userData, company: userCompany };
  }
  return null;
};

export const getNameFromEmail = async (email) => {
  if (!email) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }

  const userDocRef = doc(db, "users", email);
  const userData = await getDoc(userDocRef);
  if (userData !== null) {
    return userData.data();
  }
  return null;
};

export const getAllLevels = async (companyName, category) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companyUsers = collection(companyDocRef, `${category}`);
  const q = query(companyUsers);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No ${category}`);
    return [];
  }
  const levels = [];
  querySnapshot.docs.map((docSnapshot) => {
    if (!levels.includes(docSnapshot.data().level.toString())) {
      levels.push(docSnapshot.data().level.toString());
    }
  });
  return levels.sort();
};

export const getParkingDay = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companyUsers = collection(companyDocRef, `parkings`);
  const q = query(companyUsers);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No parkings`);
    return [];
  }
  const companyData = await getDoc(companyDocRef);
  const plDocRef = doc(db, "parkinglots", companyData.data().parking_id);
  const freePakringsCol = collection(plDocRef, `free-parkings`);
  let fpq = query(freePakringsCol);
  let freePakringsSnapshot = await getDocs(fpq);
  let listOfCommunityParkings = [];
  freePakringsSnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    listOfCommunityParkings.push(doc.id);
  });
  let dataMap = [];
  dataMap = await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      let community = null;
      if (listOfCommunityParkings.includes(docSnapshot.id)) {
        // check if users in parking are from the same company as the parking owner - if yes let the owner edit (dont change community)
        if (docSnapshot.data().taken !== null) {
          const userDoc = doc(db, "users", docSnapshot.data().taken);
          const userData = await getDoc(userDoc);
          if (userData.exists()) {
            if (userData.data().companyName !== companyName) {
              community = companyName;
            }
          }
        }
        if (community === null) {
          if (docSnapshot.data().taken_dp !== null) {
            const userDoc = doc(db, "users", docSnapshot.data().taken_dp);
            const userData = await getDoc(userDoc);
            if (userData.exists()) {
              if (userData.data().companyName !== companyName) {
                community = companyName;
              }
            }
          }
        }
        // community = companyName;
      }
      return {
        id: `${docSnapshot.data().level}:${docSnapshot.data().parking_id}`,
        level: docSnapshot.data().level,
        parking_id: docSnapshot.data().parking_id,
        taken: docSnapshot.data().taken,
        double_parking: docSnapshot.data().double_parking,
        taken_dp: docSnapshot.data().taken_dp,
        priority: docSnapshot.data().priority,
        electric: docSnapshot.data().electric,
        community: community,
        // community:
        //   docSnapshot.data().hasOwnProperty("user_company") ||
        //   docSnapshot.data().hasOwnProperty("user_company_dp")
        //     ? companyName
        //     : null,
        user_company: docSnapshot.data().hasOwnProperty("user_company")
          ? docSnapshot.data().user_company
          : null,
        user_company_dp: docSnapshot.data().hasOwnProperty("user_company_dp")
          ? docSnapshot.data().user_company_dp
          : null,
      };
    })
  );
  // console.log(dataMap);
  // Check if any users of the company has taken a parking from the community and showcase it
  if (freePakringsSnapshot.docs.length !== 0) {
    // Checks if free parkings exist - should not before 9:30
    fpq = query(freePakringsCol, where("user_company", "==", companyName));
    freePakringsSnapshot = await getDocs(fpq);
    if (freePakringsSnapshot.docs.length !== 0) {
      freePakringsSnapshot.docs.forEach((doc) => {
        const index = dataMap.findIndex((e) => e.id === doc.id);
        // Check to prevent parkings showing more then once in status grid
        if (index > -1) {
        } else {
          dataMap.push({
            id: `${doc.data().level}:${doc.data().parking_id}`,
            level: doc.data().level,
            parking_id: doc.data().parking_id,
            taken: doc.data().taken === "0" ? "רכב לא מוכר" : doc.data().taken,
            double_parking: doc.data().double_parking,
            taken_dp:
              doc.data().taken_dp === "0" ? "רכב לא מוכר" : doc.data().taken_dp,
            priority: doc.data().priority,
            community: doc.data().owner,
            user_company: doc.data().hasOwnProperty("user_company")
              ? doc.data().user_company
              : null,
            user_company_dp: doc.data().hasOwnProperty("user_company_dp")
              ? doc.data().user_company_dp
              : null,
          });
        }
      });
    }
    fpq = query(freePakringsCol, where("user_company_dp", "==", companyName));
    freePakringsSnapshot = await getDocs(fpq);
    if (freePakringsSnapshot.docs.length !== 0) {
      freePakringsSnapshot.docs.forEach((doc) => {
        const index = dataMap.findIndex((e) => e.id === doc.id);
        // Check to prevent parkings showing more then once in status grid
        if (index > -1) {
        } else {
          dataMap.push({
            id: `${doc.data().level}:${doc.data().parking_id}`,
            level: doc.data().level,
            parking_id: doc.data().parking_id,
            taken: doc.data().taken === "0" ? "רכב לא מוכר" : doc.data().taken,
            double_parking: doc.data().double_parking,
            taken_dp:
              doc.data().taken_dp === "0" ? "רכב לא מוכר" : doc.data().taken_dp,
            priority: doc.data().priority,
            community: doc.data().owner,
            user_company: doc.data().hasOwnProperty("user_company")
              ? doc.data().user_company
              : null,
            user_company_dp: doc.data().hasOwnProperty("user_company_dp")
              ? doc.data().user_company_dp
              : null,
          });
        }
      });
    }
  }
  // dataMap.sort((a, b) => {
  //     try {
  //       if (Number(a.level) > Number(b.level)) {
  //         return 1;
  //       } else if (Number(a.level) < Number(b.level)) {
  //         return -1;
  //       } else {
  //         if (isNumeric(a.parking_id)) {
  //           return Number(a.parking_id) > Number(b.parking_id) ? 1 : -1;
  //         }
  //         if (a.parking_id.split(/(\d+)/)[0] > b.parking_id.split(/(\d+)/)[0]) {
  //           return 1;
  //         } else if (
  //           a.parking_id.split(/(\d+)/)[0] < b.parking_id.split(/(\d+)/)[0]
  //         ) {
  //           return -1;
  //         } else {
  //           if (
  //             Number(a.parking_id.split(/(\d+)/)[1]) >
  //             Number(b.parking_id.split(/(\d+)/)[1])
  //           ) {
  //             return 1;
  //           } else if (
  //             Number(a.parking_id.split(/(\d+)/)[1]) <
  //             Number(b.parking_id.split(/(\d+)/)[1])
  //           ) {
  //             return -1;
  //           } else {
  //           }
  //         }
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     return a.id > b.id ? 1 : -1;
  //   });
  dataMap.sort((a, b) => {
    try {
      if (a.level.length > b.level.length) return 1;
      else if (a.level.length < b.level.length) return -1;
      else {
        if (a.level > b.level) return 1;
        else if (a.level < b.level) return -1;
        else {
          if (a.parking_id.length > b.parking_id.length) return 1;
          else if (a.parking_id.length < b.parking_id.length) return -1;
          else {
            if (a.parking_id > b.parking_id) return 1;
            else if (a.parking_id < b.parking_id) return -1;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return a.id > b.id ? 1 : -1;
  });
  console.log(dataMap);
  return dataMap;
};
// function isNumeric(n) {
//   return !isNaN(parseFloat(n)) && isFinite(n);
// }

export const getReportDataFull = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const reportCol = collection(companyDocRef, `report-month`);
  const q = query(reportCol);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No history`);
    return;
  }
  let dataMap = [];
  querySnapshot.docs.map((docSnapshot) => {
    let withId = {};
    for (const [key, parking] of Object.entries(docSnapshot.data())) {
      withId[key] = { ...parking, id: key };
    }
    console.log(withId);
    dataMap[`${docSnapshot.id}`] = withId;
    // return {
    //   [`${docSnapshot.id}`]: docSnapshot.data(),
    // };
  });
  console.log(dataMap);
  return dataMap;
};

export const getReportDataFromDate = async (companyName, date, category) => {
  if (!companyName || !date) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  console.log(date);
  const companyDocRef = doc(db, "companies", companyName);
  const dateDoc = doc(
    companyDocRef,
    category === "parkings" ? `report-month` : `report-month-desks`,
    date
  );
  const docData = await getDoc(dateDoc);
  if (!docData.exists()) {
    console.log(`Date doesnt exist in report`);
    return [];
  }
  let finalData = [];
  if (category === "parkings") {
    for (const [key, parking] of Object.entries(docData.data())) {
      let parkingCommunityOwner = null;
      if (
        parking.taken &&
        parking.user_company !== companyName &&
        parking.user_company !== null &&
        parking.user_company !== "?"
      ) {
        parkingCommunityOwner = parking.owner;
      }
      if (
        parking.taken_dp &&
        parking.user_company_dp !== companyName &&
        parking.user_company_dp !== null &&
        parking.user_company_dp !== "?"
      ) {
        parkingCommunityOwner = parking.owner;
      }
      finalData.push({
        ...parking,
        // owner: parking.owner === companyName ? null : parking.owner,
        owner: parkingCommunityOwner,
        id: `${parking.level}:${parking.parking_id}`,
      });
    }
    finalData.sort((a, b) => {
      try {
        if (a.level.length > b.level.length) return 1;
        else if (a.level.length < b.level.length) return -1;
        else {
          if (a.level > b.level) return 1;
          else if (a.level < b.level) return -1;
          else {
            if (a.parking_id.length > b.parking_id.length) return 1;
            else if (a.parking_id.length < b.parking_id.length) return -1;
            else {
              if (a.parking_id > b.parking_id) return 1;
              else if (a.parking_id < b.parking_id) return -1;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      return a.id > b.id ? 1 : -1;
    });
  } else {
    for (const [key, desk] of Object.entries(docData.data())) {
      finalData.push({
        ...desk,
        id: `${desk.level}:${desk.desk_id}`,
      });
    }
    finalData.sort((a, b) => {
      try {
        if (a.level.length > b.level.length) return 1;
        else if (a.level.length < b.level.length) return -1;
        else {
          if (a.level > b.level) return 1;
          else if (a.level < b.level) return -1;
          else {
            if (a.desk_id.length > b.desk_id.length) return 1;
            else if (a.desk_id.length < b.desk_id.length) return -1;
            else {
              if (a.desk_id > b.desk_id) return 1;
              else if (a.desk_id < b.desk_id) return -1;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      return a.id > b.id ? 1 : -1;
    });
  }
  console.log(finalData);
  return finalData;
};

export const getReportData = async (companyName, category) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const reportCol = collection(
    companyDocRef,
    category === "parkings" ? `report-month` : `report-month-desks`
  );
  const q = query(reportCol);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No reports`);
    return null;
  }
  let dataMap = {};
  if (category === "parkings") {
    querySnapshot.docs.map((docSnapshot) => {
      let counterParkings = { all: 0, taken: 0 };
      let counterDp = { all: 0, taken: 0 };
      let counterElectric = { all: 0, taken: 0 };
      let counterCommunityTaken = 0;
      let counterCommunityGiven = 0;
      // console.log(docSnapshot.data());
      for (const [key, parking] of Object.entries(docSnapshot.data())) {
        if (parking.hasOwnProperty("owner") && parking.owner !== companyName) {
          // Second check so only other parkings will count
          counterCommunityTaken++;
        } else {
          counterParkings.all++;
          if (parking.hasOwnProperty("owner")) {
            if (
              parking.user_company &&
              parking.user_company !== companyName &&
              parking.user_company_dp !== "?"
            ) {
              counterCommunityGiven++;
            }
            if (
              parking.user_company_dp &&
              parking.user_company_dp !== companyName &&
              parking.user_company_dp !== "?"
            ) {
              counterCommunityGiven++;
            }
          }
        }
        if (parking.taken) counterParkings.taken++;
        if (parking.electric) {
          counterElectric.all++;
          if (parking.taken) counterElectric.taken++;
          // if (parking.taken_dp) counterElectric.taken++;
        } else {
          if (parking.double_parking) {
            if (
              parking.hasOwnProperty("owner") &&
              parking.owner !== companyName
            ) {
              console.log();
            } else {
              counterDp.all++;
              if (parking.taken_dp) counterDp.taken++;
            }
          }
        }
      }

      dataMap[`${docSnapshot.id}`] = {
        id: docSnapshot.id,
        counterParkings,
        counterDp,
        counterElectric,
        counterCommunityTaken,
        counterCommunityGiven,
      };
    });
  } else {
    querySnapshot.docs.map((docSnapshot) => {
      let counterDesks = { all: 0, taken: 0 };
      for (const [key, desk] of Object.entries(docSnapshot.data())) {
        counterDesks.all++;
        if (desk.taken) counterDesks.taken++;
      }
      dataMap[`${docSnapshot.id}`] = {
        id: docSnapshot.id,
        counterDesks,
      };
    });
  }
  console.log(dataMap);
  return dataMap;
};

export const getReportDates = async (companyName, category) => {
  if (!companyName || !category) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  // const reportCol = collection(companyDocRef, `report-month-${category}`);
  const reportCol = collection(
    companyDocRef,
    category === "parkings" ? `report-month` : `report-month-desks`
  );
  const q = query(reportCol);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No reports`);
    return null;
  }
  const dataMap = querySnapshot.docs.map((docSnapshot) => docSnapshot.id);
  // console.log(dataMap);
  return dataMap;
};

export const getDashboardDataFromDate = async (companyName, date) => {
  if (companyName === null || date === null) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  // console.log(date);
  const companyDocRef = doc(db, "companies", companyName);
  const dateDoc = doc(companyDocRef, `report-month`, date);
  const docData = await getDoc(dateDoc);
  if (!docData.exists()) {
    console.log(`Date doesnt exist in report`);
    return;
  }
  let finalData = {};
  let taken = 0;
  let taken_ev = 0;
  let ev = 0;
  let taken_dp = 0;
  let dp = 0;
  let taken_community = 0;
  let taken_dp_community = 0;
  let given_community = 0;
  let given_dp_community = 0;
  let level = {};
  let priority = {};
  for (const [key, parking] of Object.entries(docData.data())) {
    if (parking.taken) {
      if (parking.hasOwnProperty("owner") && parking.owner !== companyName) {
        // Second check so only other parkings will count
        taken_community++;
      } else {
        taken++;
      }
      if (
        parking.hasOwnProperty("user_company") &&
        parking.user_company !== companyName &&
        parking.user_company !== null &&
        parking.user_company !== "?"
      ) {
        if (parking.hasOwnProperty("owner") && parking.owner === companyName) {
          // Check if the parking is owend by users company
          // Second check so only other parkings will count
          console.log(parking.taken);
          console.log(parking.user_company);
          given_community++;
        }
      }
    }
    // if (parking.taken_dp) {
    //   if (parking.hasOwnProperty("owner") && parking.owner !== companyName) {
    //     // Second check so only other parkings will count
    //     taken_dp_community++;
    //   } else {
    //     taken_dp++;
    //   }
    // }
    if (parking.electric) {
      ev++;
      if (parking.taken) taken_ev++;
    } else {
      if (parking.double_parking) {
        parking.hasOwnProperty("owner") && parking.owner !== companyName
          ? (dp = dp)
          : dp++;
        if (parking.taken_dp) {
          if (
            parking.hasOwnProperty("owner") &&
            parking.owner !== companyName
          ) {
            // Second check so only other parkings will count
            taken_dp_community++;
          } else {
            taken_dp++;
          }
          if (
            parking.hasOwnProperty("user_company_dp") &&
            parking.user_company_dp !== companyName &&
            parking.user_company_dp !== null &&
            parking.user_company_dp !== "?"
          ) {
            if (
              parking.hasOwnProperty("owner") &&
              parking.owner === companyName
            ) {
              // Second check so only other parkings will count
              given_dp_community++;
            }
          }
        }
      }
    }
    // if (parking.double_parking)
    //   parking.hasOwnProperty("owner") && parking.owner !== companyName
    //     ? (dp = dp)
    //     : dp++;
    if (parking.level in level) {
      level[parking.level].all = level[parking.level].all + 1;
      if (parking.taken)
        level[parking.level].taken = level[parking.level].taken + 1;
      if (parking.taken_dp)
        level[parking.level].taken = level[parking.level].taken + 1;
    } else {
      level[parking.level] = { all: 1, taken: 0 };
      if (parking.taken)
        level[parking.level].taken = level[parking.level].taken + 1;
      if (parking.taken_dp)
        level[parking.level].taken = level[parking.level].taken + 1;
    }
    if (parking.priority in priority) {
      priority[parking.priority].all = priority[parking.priority].all + 1;
      if (parking.taken)
        priority[parking.priority].taken = priority[parking.priority].taken + 1;
      if (parking.taken_dp)
        priority[parking.priority].taken = priority[parking.priority].taken + 1;
    } else {
      priority[parking.priority] = { all: 1, taken: 0 };
      if (parking.taken)
        priority[parking.priority].taken = priority[parking.priority].taken + 1;
      if (parking.taken_dp)
        priority[parking.priority].taken = priority[parking.priority].taken + 1;
    }
  }
  finalData = {
    date,
    all: Object.keys(docData.data()).length + dp - taken_community,
    taken: taken + taken_dp - given_community - given_dp_community,
    taken_community: taken_community + taken_dp_community,
    all_dp: dp,
    taken_dp: taken_dp - given_dp_community,
    taken_dp_community,
    given_community: given_community + given_dp_community,
    given_dp_community,
    ev,
    taken_ev,
    level,
    priority,
  };
  // console.log(finalData);
  return finalData;
};

export const setTaken = async (companyName, newDoc, change) => {
  if (companyName === null || newDoc === null || change === null) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  // Check if company locked
  const companyDoc = await getDoc(companyDocRef);
  if (companyDoc.data().hasOwnProperty("lock") && companyDoc.data().lock) {
    console.log(
      "Database locked for evening maintenance, please try again later"
    );
    throw {
      code: "655",
      message:
        "Database locked for evening maintenance, please try again later",
    };
  }
  const parkingRefToUpdate = doc(
    companyDocRef,
    "parkings",
    `${newDoc.level}:${newDoc.parking_id}`
  );
  const batch = writeBatch(db);
  const parkingDoc = await getDoc(parkingRefToUpdate);
  switch (change) {
    case 0: // taken
      if (parkingDoc.exists()) {
        // Check if already taken by user and remove that user from parking
        if (parkingDoc.data().taken !== null) {
          await removeTakenUser(
            companyName,
            parkingDoc.data().taken,
            `${newDoc.level}:${newDoc.parking_id}`,
            // `${newDoc.level}:${newDoc.double_parking}`,
            false
          );
        }
        if (newDoc.taken && newDoc.taken !== "" && newDoc.taken !== "guest") {
          await checkOtherParkings(
            companyName,
            newDoc.taken.toLowerCase(),
            `${newDoc.level}:${newDoc.double_parking}`
          );
        }
        try {
          batch.update(parkingRefToUpdate, {
            taken:
              newDoc.taken && newDoc.taken !== ""
                ? newDoc.taken.toLowerCase()
                : null,
          });
          if (!newDoc.taken || newDoc.taken === "") {
            batch.update(parkingRefToUpdate, {
              user_company: deleteField(),
            });
            batch.set(doc(db, "notify_waitlist", companyName), {
              parking_id: `${newDoc.level}:${newDoc.parking_id}`,
            });
          }
          await batch.commit();
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating doc" };
        }
      } else {
        throw { code: "3", message: "Parking with this id doesn`t exists" };
      }
      break;
    case 1: // taken dp
      if (parkingDoc.exists()) {
        // Check if already taken by user and remove that user from parking
        if (parkingDoc.data().taken_dp !== null) {
          await removeTakenUser(
            companyName,
            parkingDoc.data().taken_dp,
            `${newDoc.level}:${newDoc.parking_id}`,
            true
          );
        }
        if (
          newDoc.taken_dp &&
          newDoc.taken_dp !== "" &&
          newDoc.taken_dp !== "guest"
        ) {
          await checkOtherParkings(
            companyName,
            newDoc.taken_dp.toLowerCase(),
            `${newDoc.level}:${newDoc.parking_id}`
          );
        }
        try {
          batch.update(parkingRefToUpdate, {
            taken_dp:
              newDoc.taken_dp && newDoc.taken_dp !== ""
                ? newDoc.taken_dp.toLowerCase()
                : null,
          });
          if (!newDoc.taken_dp || newDoc.taken_dp === "") {
            batch.update(parkingRefToUpdate, {
              user_company_dp: deleteField(),
            });
            batch.set(doc(db, "notify_waitlist", companyName), {
              parking_id: `${newDoc.level}:${newDoc.parking_id}`,
            });
          }
          await batch.commit();
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating doc" };
        }
      } else {
        throw { code: "3", message: "Parking with this id doesn`t exists" };
      }
      break;
    case 2: // both
      if (parkingDoc.exists()) {
        // Check if already taken by user and remove that user from parking
        if (parkingDoc.data().taken !== null) {
          await removeTakenUser(
            companyName,
            parkingDoc.data().taken,
            `${newDoc.level}:${newDoc.parking_id}`,
            false
          );
        }
        if (parkingDoc.data().taken_dp !== null) {
          await removeTakenUser(
            companyName,
            parkingDoc.data().taken_dp,
            `${newDoc.level}:${newDoc.parking_id}`,
            // `${newDoc.level}:${newDoc.double_parking}`,
            true
          );
        }
        // Now check if the new users are in other parkings - if so remove them from there
        if (newDoc.taken && newDoc.taken !== "" && newDoc.taken !== "guest") {
          await checkOtherParkings(
            companyName,
            newDoc.taken.toLowerCase(),
            `${newDoc.level}:${newDoc.double_parking}`
          );
        }
        if (
          newDoc.taken_dp &&
          newDoc.taken_dp !== "" &&
          newDoc.taken_dp !== "guest"
        ) {
          await checkOtherParkings(
            companyName,
            newDoc.taken_dp.toLowerCase(),
            `${newDoc.level}:${newDoc.parking_id}`
          );
        }
        try {
          batch.update(parkingRefToUpdate, {
            taken:
              newDoc.taken && newDoc.taken !== ""
                ? newDoc.taken.toLowerCase()
                : null,
            taken_dp:
              newDoc.taken_dp && newDoc.taken_dp !== ""
                ? newDoc.taken_dp.toLowerCase()
                : null,
          });
          if (!newDoc.taken || newDoc.taken === "") {
            batch.update(parkingRefToUpdate, {
              user_company: deleteField(),
            });
          }
          if (!newDoc.taken_dp || newDoc.taken_dp === "") {
            batch.update(parkingRefToUpdate, {
              user_company_dp: deleteField(),
            });
          }
          if (
            !newDoc.taken_dp ||
            newDoc.taken_dp === "" ||
            !newDoc.taken ||
            newDoc.taken === ""
          ) {
            batch.set(doc(db, "notify_waitlist", companyName), {
              parking_id: `${newDoc.level}:${newDoc.parking_id}`,
            });
          }
          await batch.commit();
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating doc" };
        }
      } else {
        throw { code: "3", message: "Parking with this id doesn`t exists" };
      }
      break;
    default:
      console.log("Error reserving parking");
      return;
  }
  try {
    await setTakenUser(companyName, newDoc, change);
  } catch (e) {
    console.log(e);
    throw { code: "5", message: "Error updating user doc" };
  }
  try {
    // Check if time and participation is relevent
    // Set in community taken as well
    if (await checkParticipate(companyName)) {
      await setTakenCommunity(companyName, newDoc, change);
    } else if (await checkParticipateSettings(companyName)) {
      const plDoc = doc(db, `parkinglots`, companyDoc.data().parking_id);
      const plSnapshot = await getDoc(plDoc);
      if (checkTime(plSnapshot.data().create_time)) {
        console.log("going inside update community to add");
        updateCommunityParking(
          companyName,
          `${newDoc.level}:${newDoc.parking_id}`
        );
      }
    }
  } catch (e) {
    console.log(e);
    throw { code: "5", message: "Error updating comunoity doc" };
  }
  if (
    (change === 0 || change === 2) &&
    newDoc.taken !== "" &&
    newDoc.taken !== null
  ) {
    createNotification(
      newDoc.taken?.toLowerCase(),
      `${newDoc.level}:${newDoc.parking_id}`
    );
  }
  if (
    (change === 1 || change === 2) &&
    newDoc.taken_dp !== "" &&
    newDoc.taken_dp !== null
  ) {
    createNotification(
      newDoc.taken_dp?.toLowerCase(),
      `${newDoc.level}:${newDoc.double_parking}`
    );
  }
  if (newDoc.double_parking !== null) {
    console.log(newDoc.taken);
    console.log(newDoc.taken_dp);
    if (change === 0) {
      if (newDoc.taken_dp === null) {
        createNotificationDp(newDoc.taken_dp?.toLowerCase(), "");
      } else {
        // Taken has changed and taken_dp hasnt
        if (newDoc.taken_dp !== "" && newDoc.taken !== "") {
          //notifiy user that is already in parking
          let email = newDoc.taken?.toLowerCase();
          if (newDoc.taken && !newDoc.taken.includes("@")) {
            const userData = await checkCarNumber(companyName, newDoc.taken);
            email = userData !== null ? userData.email : "רכב לא ידוע";
          }
          createNotificationDp(newDoc.taken_dp?.toLowerCase(), email);
        }
      }
    } else if (change === 1) {
      if (newDoc.taken_dp === null) {
        createNotificationDp(newDoc.taken?.toLowerCase(), "");
      } else {
        // Taken_dp has changed and taken hasnt
        if (newDoc.taken !== "" && newDoc.taken_dp !== "") {
          //notifiy user that is already in parking
          let email = newDoc.taken_dp?.toLowerCase();
          if (newDoc.taken_dp && !newDoc.taken_dp.includes("@")) {
            const userData = await checkCarNumber(companyName, newDoc.taken_dp);
            email = userData !== null ? userData.email : "רכב לא ידוע";
          }
          console.log(email);
          createNotificationDp(newDoc.taken?.toLowerCase(), email);
        }
      }
    }
  }
  return "Taken has been updated";
};

export const setTakenUser = async (companyName, newDoc, change) => {
  const companyDocRef = doc(db, "companies", companyName);
  const batch = writeBatch(db);
  if (change === 0 || change === 2) {
    if (newDoc.taken) {
      const userRefToUpdate = doc(companyDocRef, "users", newDoc.taken.trim());
      const usersDoc = await getDoc(userRefToUpdate);
      if (usersDoc.exists()) {
        try {
          batch.update(userRefToUpdate, {
            // parking: newDoc.parking_id,
            parking: `${newDoc.level}:${newDoc.parking_id}`,
          });
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating user" };
        }
      }
    }
  }
  if (change === 1 || change === 2) {
    if (newDoc.taken_dp) {
      const userDpRefToUpdate = doc(
        companyDocRef,
        "users",
        newDoc.taken_dp.trim()
      );
      const userDpDoc = await getDoc(userDpRefToUpdate);
      if (userDpDoc.exists()) {
        try {
          batch.update(userDpRefToUpdate, {
            // parking: newDoc.double_parking,
            parking: `${newDoc.level}:${newDoc.double_parking}`,
          });
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating user" };
        }
      }
    }
  }
  await batch.commit();
  return "Users parking has been updated";
};

export const setTakenCommunity = async (companyName, newDoc, change) => {
  console.log("in set Taken Community");
  const companyDocRef = doc(db, "companies", companyName);
  const parkingDocRef = doc(
    companyDocRef,
    "parkings",
    `${newDoc.level}:${newDoc.parking_id}`
  );
  const companyData = await getDoc(companyDocRef);
  const parkingData = await getDoc(parkingDocRef);
  console.log(parkingData.data());
  if (parkingData.get("electric") === true) return;
  // check if company participates in community
  const communityDocRef = doc(db, "parkinglots", companyData.data().parking_id);
  const communityParkingRefToUpdate = doc(
    communityDocRef,
    "free-parkings",
    `${newDoc.level}:${newDoc.parking_id}`
  );
  const communityParkingDoc = await getDoc(communityParkingRefToUpdate);
  const batch = writeBatch(db);
  // Check from wich company each user is
  // I need to think if this is actually needed cause it should be locked if the parking is in the community state
  console.log(communityParkingDoc.exists());
  console.log(newDoc.taken);
  console.log(change);
  if (change === 0 || change === 2) {
    if (communityParkingDoc.exists()) {
      if (newDoc.taken && newDoc.taken !== "") {
        const userDocRef = doc(db, "users", newDoc.taken?.toLowerCase());
        const userData = await getDoc(userDocRef);
        try {
          batch.update(communityParkingRefToUpdate, {
            taken: newDoc.taken.toLowerCase(),
            user_company: userData.exists()
              ? userData.data().companyName
              : null,
          });
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating user" };
        }
      } else {
        try {
          batch.update(communityParkingRefToUpdate, {
            taken: null,
            user_company: null,
          });
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating user" };
        }
      }
    } else {
      batch.set(communityParkingRefToUpdate, {
        ...parkingData.data(),
        taken: newDoc.taken && newDoc.taken !== "" ? newDoc.taken : null,
        owner: companyName,
        user_company_dp:
          newDoc.taken_dp && newDoc.taken_dp !== "" ? companyName : null,
        user_company: newDoc.taken && newDoc.taken !== "" ? companyName : null,
      });
    }
  }
  if (change === 1 || change === 2) {
    if (communityParkingDoc.exists()) {
      if (newDoc.taken_dp) {
        const userDpDocRef = doc(db, "users", newDoc.taken_dp?.toLowerCase());
        const userDpData = await getDoc(userDpDocRef);
        try {
          batch.update(communityParkingRefToUpdate, {
            taken_dp:
              newDoc.taken_dp && newDoc.taken_dp !== ""
                ? newDoc.taken_dp.toLowerCase()
                : null,
            user_company_dp: userDpData.exists()
              ? userDpData.data().companyName
              : null,
          });
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating user" };
        }
      } else {
        try {
          batch.update(communityParkingRefToUpdate, {
            taken_dp: null,
            user_company_dp: null,
          });
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating user" };
        }
      }
    } else {
      console.log(parkingData.data());
      batch.set(communityParkingRefToUpdate, {
        ...parkingData.data(),
        taken_dp:
          newDoc.taken && newDoc.taken_dp !== "" ? newDoc.taken_dp : null,
        owner: companyName,
        user_company: newDoc.taken && newDoc.taken !== "" ? companyName : null,
        user_company_dp:
          newDoc.taken_dp && newDoc.taken_dp !== "" ? companyName : null,
      });
    }
  }
  try {
    await batch.commit();
  } catch (e) {
    console.log(e);
  }
  return "Users parking has been updated";
};

export const removeTakenUser = async (
  companyName,
  userToRemove,
  parking,
  isDp
) => {
  if (!companyName || !userToRemove) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }
  console.log(parking);
  const companyDocRef = doc(db, "companies", companyName);
  const userRefToRemove = doc(companyDocRef, "users", userToRemove.trim());
  const batch = writeBatch(db);
  const userDoc = await getDoc(userRefToRemove);
  if (userDoc.exists()) {
    try {
      batch.update(userRefToRemove, {
        parking: null,
      });
      console.log(userDoc.data().parking);
      createNotification(userToRemove, "null");
      // if (userDoc.data().parking !== parking) {
      //   createNotification(userToRemove, "null");
      // }
    } catch (error) {
      console.log("error: ", error);
      throw { code: "4", message: "Error while updating user" };
    }
  } else {
    return "User with this email doesnt exist";
  }
  let parkingSameCompany = true;
  const parkingsDoc = doc(companyDocRef, `parkings`, parking);
  const parkingToUpdate = await getDoc(parkingsDoc);
  if (parkingToUpdate.exists()) {
    if (isDp === false) {
      batch.update(parkingsDoc, {
        taken: null,
        user_company: deleteField(),
      });
    } else {
      batch.update(parkingsDoc, {
        taken_dp: null,
        user_company_dp: deleteField(),
      });
    }
    if (parkingToUpdate.data().double_parking !== null) {
      if (isDp === false) {
        if (parkingToUpdate.data().taken !== null) {
          createNotificationDp(parkingToUpdate.data().taken, "");
        }
      } else {
        if (parkingToUpdate.data().taken_dp !== null) {
          createNotificationDp(parkingToUpdate.data().taken_dp, "");
        }
      }
    }
  } else {
    parkingSameCompany = false;
  }
  if (parkingSameCompany && parkingToUpdate.get("electric") === true) {
    // Electric pakings are not in comomunity
  } else {
    if (await checkParticipate(companyName)) {
      const companyData = await getDoc(companyDocRef);
      const plDoc = doc(db, `parkinglots`, companyData.data().parking_id);
      const freeParkingsDoc = doc(plDoc, `free-parkings`, parking);
      const communityParkingToRemove = await getDoc(freeParkingsDoc);
      if (communityParkingToRemove.exists()) {
        if (isDp === false) {
          batch.update(freeParkingsDoc, {
            taken: null,
            user_company: null,
          });
        } else {
          batch.update(freeParkingsDoc, {
            taken_dp: null,
            user_company_dp: null,
          });
        }
        if (communityParkingToRemove.data().double_parking !== null) {
          if (isDp === false) {
            if (communityParkingToRemove.data().taken !== null) {
              createNotificationDp(communityParkingToRemove.data().taken, "");
            }
          } else {
            if (communityParkingToRemove.data().taken_dp !== null) {
              createNotificationDp(
                communityParkingToRemove.data().taken_dp,
                ""
              );
            }
          }
        }
      } else {
        console.log(
          "- Parking doesnt exist in community but should - Creating now -"
        );
        // If in here means the parking hasnt been added yet to community parking
        // Should create the parking in community
        if (parkingToUpdate.get("electric") === false) {
          let otherUserCompany = null;
          if (isDp === false) {
            if (parkingToUpdate.data().double_parking !== null) {
              if (parkingToUpdate.data().taken_dp != null) {
                otherUserCompany = companyName;
              }
            }
            batch.set(freeParkingsDoc, {
              ...parkingToUpdate.data(),
              taken: null,
              owner: companyName,
              user_company: null,
              user_company_dp: otherUserCompany,
            });
          } else {
            if (parkingToUpdate.data().taken != null) {
              otherUserCompany = companyName;
            }
            batch.set(freeParkingsDoc, {
              ...parkingToUpdate.data(),
              taken_dp: null,
              owner: companyName,
              user_company: otherUserCompany,
              user_company_dp: null,
            });
          }
        }
      }
    }
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while updating user" };
  }
  return "Users parking has been updated";
};

// export const removeTakenUser = async (companyName, userToRemove, parking, isDp) => {
//   if (!companyName || !userToRemove) {
//     console.log("error: data not received");
//     throw { code: "655", message: "data not received" };
//   }
//   console.log(parking);
//   const companyDocRef = doc(db, "companies", companyName);
//   const userRefToRemove = doc(companyDocRef, "users", userToRemove.trim());
//   const batch = writeBatch(db);
//   const userDoc = await getDoc(userRefToRemove);
//   if (userDoc.exists()) {
//     try {
//       batch.update(userRefToRemove, {
//         parking: null,
//       });
//       console.log(userDoc.data().parking);
//       if (userDoc.data().parking !== parking) {
//         createNotification(userToRemove, "null");
//       }
//     } catch (error) {
//       console.log("error: ", error);
//       throw { code: "4", message: "Error while updating user" };
//     }
//   } else {
//     return "User with this email doesnt exist";
//   }
//   if (await checkParticipate(companyName)) {
//     const companyData = await getDoc(companyDocRef);
//     const plDoc = doc(db, `parkinglots`, companyData.data().parking_id);
//     const freeParkingsDoc = doc(plDoc, `free-parkings`, userDoc.data().parking);
//     const communityParkingToRemove = await getDoc(freeParkingsDoc);
//     if (communityParkingToRemove.exists()) {
//       batch.update(freeParkingsDoc, {
//         taken: null,
//         user_company: null,
//       });
//       if (communityParkingToRemove.data().double_parking !== null) {
//         if (communityParkingToRemove.data().taken_dp !== null) {
//           createNotificationDp(communityParkingToRemove.data().taken_dp, "");
//         }
//       }
//     } else {
//       // Check if maybe the parking is a dp
//       console.log("-1-");
//       const q = query(
//         collection(plDoc, "free-parkings"),
//         where("taken_dp", "==", userDoc.data().email)
//       );
//       const querySnapshot = await getDocs(q);
//       if (!querySnapshot.empty) {
//         const parkingToUpdate = doc(
//           plDoc,
//           "free-parkings",
//           `${querySnapshot.docs[0].data().level}:${
//             querySnapshot.docs[0].data().parking_id
//           }`
//         );
//         const parkingToUpdateData = await getDoc(parkingToUpdate);
//         if (parkingToUpdateData.exists()) {
//           batch.update(parkingToUpdate, {
//             taken_dp: null,
//             user_company_dp: null,
//           });
//           if (parkingToUpdateData.data().double_parking !== null) {
//             if (parkingToUpdateData.data().taken !== null) {
//               createNotificationDp(parkingToUpdateData.data().taken, "");
//             }
//           }
//         }
//       } else {
//         // If in here means the parking hasnt been added yet to community parking
//         // Should create the parking in community
//         batch.set(freeParkingsDoc, {
//           ...parkingData.data(),
//           taken_dp:
//             newDoc.taken && newDoc.taken_dp !== "" ? newDoc.taken_dp : null,
//           owner: companyName,
//           user_company:
//             newDoc.taken && newDoc.taken !== "" ? companyName : null,
//           user_company_dp:
//             newDoc.taken_dp && newDoc.taken_dp !== "" ? companyName : null,
//         });
//       }
//     }
//   } else {
//     const parkingsDoc = doc(companyDocRef, `parkings`, userDoc.data().parking);
//     const parkingToUpdate = await getDoc(parkingsDoc);
//     if (parkingToUpdate.exists()) {
//       batch.update(parkingsDoc, {
//         taken: null,
//         user_company: deleteField(),
//       });
//       if (parkingToUpdate.data().double_parking !== null) {
//         if (parkingToUpdate.data().taken_dp !== null) {
//           createNotificationDp(parkingToUpdate.data().taken_dp, "");
//         }
//       }
//     } else {
//       const q = query(
//         collection(companyDocRef, "parkings"),
//         where("taken_dp", "==", userDoc.data().email)
//       );
//       const querySnapshot = await getDocs(q);
//       if (!querySnapshot.empty) {
//         const parkingToUpdateRef = doc(
//           companyDocRef,
//           "parkings",
//           `${querySnapshot.docs[0].data().level}:${
//             querySnapshot.docs[0].data().parking_id
//           }`
//         );
//         const parkingToUpdate = await getDoc(parkingToUpdateRef);
//         if (parkingToUpdate.exists()) {
//           batch.update(parkingToUpdateRef, {
//             taken_dp: null,
//             user_company_dp: deleteField(),
//           });
//           if (parkingToUpdate.data().double_parking !== null) {
//             if (parkingToUpdate.data().taken !== null) {
//               createNotificationDp(parkingToUpdate.data().taken, "");
//             }
//           }
//         }
//       }
//     }
//   }
//   try {
//     await batch.commit();
//   } catch (error) {
//     console.log("error: ", error);
//     throw { code: "4", message: "Error while updating user" };
//   }
//   return "Users parking has been updated";
// };

export const checkOtherParkings = async (companyName, userToCheck, parking) => {
  // Check if the user we are giving him a new parking already has a parking elsewhere, if yes remove it
  if (!companyName || !userToCheck) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const userRefToCheck = doc(companyDocRef, "users", userToCheck.trim());
  const batch = writeBatch(db);
  const companyData = await getDoc(companyDocRef);
  const userDoc = await getDoc(userRefToCheck);
  if (userDoc.exists()) {
    if (!userDoc.data().parking) return;

    let foundParking = false;
    // If users parking is electric parking then it wont be here, need to check what to do then
    // First check if user is electric -> if yes check if his parking is an electric parking -> if yes find parking in comapny  -> else continue
    // Maybe remove the check if user is electric because admin can put nhim in electric parking from web platform and that will cause bugs.
    // if (userDoc.get("electric") === true) {
    //   const parkingRefToRemove = doc(
    //     companyDocRef,
    //     "parkings",
    //     userDoc.data().parking
    //   );
    //   console.log(userDoc.data().parking);
    //   const parkingDoc = await getDoc(parkingRefToRemove);
    //   if (parkingDoc.exists()) {
    //     if (parkingDoc.get("electric") === true) {
    //       // Taken
    //       try {
    //         batch.update(parkingRefToRemove, {
    //           taken: null,
    //         });
    //         foundParking = true;
    //         console.log("in electric -> taken");
    //         if (
    //           parkingDoc.data().double_parking !== null &&
    //           parkingDoc.data().taken_dp !== null &&
    //           userDoc.data().parking !== parking
    //         ) {
    //           createNotificationDp(parkingDoc.data().taken_dp, "");
    //         }
    //       } catch (error) {
    //         console.log("error: ", error);
    //         throw { code: "4", message: "Error while updating parking" };
    //       }
    //     }
    //   } else {
    //     // Taken_dp
    //     let q = query(
    //       collection(companyDocRef, "parkings"),
    //       where("double_parking", "==", userDoc.data().parking.split(":")[1]),
    //       where("level", "==", userDoc.data().parking.split(":")[0])
    //     );
    //     let querySnapshot = await getDocs(q);
    //     if (!querySnapshot.empty) {
    //       const toRemove = `${querySnapshot.docs[0].data().level}:${
    //         querySnapshot.docs[0].data().parking_id
    //       }`;
    //       const parkingRefToRemove = doc(companyDocRef, "parkings", toRemove);
    //       const parkingDoc = await getDoc(parkingRefToRemove);
    //       if (parkingDoc.exists()) {
    //         if (parkingDoc.get("electric") === true) {
    //           try {
    //             batch.update(parkingRefToRemove, {
    //               taken_dp: null,
    //             });
    //             foundParking = true;
    //             if (
    //               parkingDoc.data().taken !== null &&
    //               userDoc.data().parking !== parking
    //             ) {
    //               createNotificationDp(parkingDoc.data().taken, "");
    //             }
    //           } catch (error) {
    //             console.log("error: ", error);
    //             throw { code: "4", message: "Error while updating parking" };
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    if (!foundParking) {
      if (await checkParticipate(companyName)) {
        console.log("community");
        // Community
        const communityRef = doc(
          db,
          "parkinglots",
          companyData.data().parking_id
        );
        const communityRefToRemove = doc(
          communityRef,
          "free-parkings",
          userDoc.data().parking
        );
        try {
          const communityDoc = await getDoc(communityRefToRemove);
          if (communityDoc.exists()) {
            // Taken
            batch.update(communityRefToRemove, {
              taken: null,
              user_company: null,
            });
            foundParking = true;
            // Get real parking and change there
            const communityCompanyRef = doc(
              db,
              "companies",
              communityDoc.data().owner
            );
            const parkingRefToRemove = doc(
              communityCompanyRef,
              "parkings",
              userDoc.data().parking
            );
            console.log(`company: ${communityDoc.data().owner}`);
            console.log(`parking: ${userDoc.data().parking}`);
            console.log("should remove parking from taken here:");
            batch.update(parkingRefToRemove, {
              taken: null,
              user_company: null,
            });
            if (
              communityDoc.data().double_parking !== null &&
              communityDoc.data().taken_dp !== null &&
              userDoc.data().parking !== parking
            ) {
              createNotificationDp(communityDoc.data().taken_dp, "");
            }
          } else {
            // Taken_dp
            const q = query(
              collection(communityRef, "free-parkings"),
              where(
                "double_parking",
                "==",
                userDoc.data().parking.split(":")[1]
              ),
              where("level", "==", userDoc.data().parking.split(":")[0])
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const communityRefToRemove = doc(
                communityRef,
                "free-parkings",
                querySnapshot.docs[0].id
              );
              const communityDoc = await getDoc(communityRefToRemove);
              if (communityDoc.exists()) {
                batch.update(communityRefToRemove, {
                  taken_dp: null,
                  user_company_dp: null,
                });
                foundParking = true;
                // Get real parking and change there
                const communityCompanyRef = doc(
                  db,
                  "companies",
                  communityDoc.data().owner
                );
                const companyRefToRemove = doc(
                  communityCompanyRef,
                  "parkings",
                  querySnapshot.docs[0].id
                );
                const companyParkingDoc = await getDoc(companyRefToRemove);
                if (companyParkingDoc.exists()) {
                  batch.update(companyRefToRemove, {
                    taken_dp: null,
                    user_company_dp: deleteField(),
                  });
                  if (
                    companyParkingDoc.data().taken !== null &&
                    userDoc.data().parking !== parking
                  ) {
                    createNotificationDp(companyParkingDoc.data().taken, "");
                  }
                }
                // let q = query(
                //   collection(communityCompanyRef, "parkings"),
                //   where(
                //     "double_parking",
                //     "==",
                //     userDoc.data().parking.split(":")[1]
                //   ),
                //   where("level", "==", userDoc.data().parking.split(":")[0])
                // );
                // let querySnapshot = await getDocs(q);
                // console.log(querySnapshot);
                // if (!querySnapshot.empty) {
                //   const toRemove = `${querySnapshot.docs[0].data().level}:${
                //     querySnapshot.docs[0].data().parking_id
                //   }`;
                //   const parkingRefToRemove = doc(
                //     communityCompanyRef,
                //     "parkings",
                //     toRemove
                //   );
                //   batch.update(parkingRefToRemove, {
                //     taken_dp: null,
                //     user_company_dp: null,
                //   });
                // }
              }
            } else {
              // add parking to community
              updateCommunityParking(companyName, userDoc.data().parking);
            }
          }
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while updating parking" };
        }
      }
      if (!foundParking) {
        console.log("regular");
        // Regular
        const parkingRefToRemove = doc(
          companyDocRef,
          "parkings",
          userDoc.data().parking
        );
        console.log(userDoc.data().parking);
        const parkingDoc = await getDoc(parkingRefToRemove);
        if (parkingDoc.exists()) {
          // Taken
          try {
            batch.update(parkingRefToRemove, {
              taken: null,
            });
            console.log("in regular -> taken");
            if (
              parkingDoc.data().double_parking !== null &&
              parkingDoc.data().taken_dp !== null &&
              userDoc.data().parking !== parking
            ) {
              createNotificationDp(parkingDoc.data().taken_dp, "");
            }
          } catch (error) {
            console.log("error: ", error);
            throw { code: "4", message: "Error while updating parking" };
          }
        } else {
          // Taken_dp
          let q = query(
            collection(companyDocRef, "parkings"),
            where("double_parking", "==", userDoc.data().parking.split(":")[1]),
            where("level", "==", userDoc.data().parking.split(":")[0])
          );
          let querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const toRemove = `${querySnapshot.docs[0].data().level}:${
              querySnapshot.docs[0].data().parking_id
            }`;
            const parkingRefToRemove = doc(companyDocRef, "parkings", toRemove);
            const parkingDoc = await getDoc(parkingRefToRemove);
            if (parkingDoc.exists()) {
              try {
                batch.update(parkingRefToRemove, {
                  taken_dp: null,
                });
                if (
                  parkingDoc.data().taken !== null &&
                  userDoc.data().parking !== parking
                ) {
                  createNotificationDp(parkingDoc.data().taken, "");
                }
              } catch (error) {
                console.log("error: ", error);
                throw { code: "4", message: "Error while updating parking" };
              }
            }
          }
        }
        if (await checkParticipateSettings(companyName)) {
          // add parking to community
          updateCommunityParking(companyName, userDoc.data().parking);
        }
      }
    }
    try {
      await batch.commit();
    } catch (error) {
      console.log("error: ", error);
      throw { code: "4", message: "Error while updating parking" };
    }
  } else {
    return "User with this email doesnt exist";
  }
  console.log("user has been removed from other parking");
  return "user has been removed from other parking";
};

export const updateCommunityParking = async (companyName, parkingId) => {
  const companyDocRef = doc(db, "companies", companyName);
  const companyData = await getDoc(companyDocRef);
  const communityDocRef = doc(db, "parkinglots", companyData.data().parking_id);
  const parkingRef = doc(companyDocRef, "parkings", parkingId);
  const parkingTakenData = await getDoc(parkingRef);
  const batch = writeBatch(db);
  if (parkingTakenData.exists()) {
    if (parkingTakenData.get("electric") === true) return;
    const communityParkingRefToUpdate = doc(
      communityDocRef,
      "free-parkings",
      parkingTakenData.id
    );
    const communityParkingDoc = await getDoc(communityParkingRefToUpdate);
    if (!communityParkingDoc.exists()) {
      batch.set(communityParkingRefToUpdate, {
        ...parkingTakenData.data(),
        taken: null,
        owner: companyName,
        user_company: null,
        user_company_dp:
          parkingTakenData.data().taken_dp !== null ? companyName : null,
      });
      const companyRefToRemove = doc(
        companyDocRef,
        "parkings",
        parkingTakenData.id
      );
      const companyParkingDoc = await getDoc(companyRefToRemove);
      if (companyParkingDoc.exists()) {
        batch.update(companyRefToRemove, {
          taken: null,
          user_company: deleteField(),
        });
        if (companyParkingDoc.data().taken_dp !== null) {
          createNotificationDp(companyParkingDoc.data().taken_dp, "");
        }
      }
    }
  } else {
    const q = query(
      collection(companyDocRef, "parkings"),
      where("double_parking", "==", parkingId.split(":")[1]),
      where("level", "==", parkingId.split(":")[0])
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const communityParkingRefToUpdate = doc(
        communityDocRef,
        "free-parkings",
        querySnapshot.docs[0].id
      );
      const communityParkingDoc = await getDoc(communityParkingRefToUpdate);
      if (!communityParkingDoc.exists()) {
        batch.set(communityParkingRefToUpdate, {
          ...querySnapshot.docs[0].data(),
          taken_dp: null,
          owner: companyName,
          user_company:
            querySnapshot.docs[0].data().taken !== null ? companyName : null,
          user_company_dp: null,
        });
        const companyRefToRemove = doc(
          companyDocRef,
          "parkings",
          querySnapshot.docs[0].id
        );
        const companyParkingDoc = await getDoc(companyRefToRemove);
        if (companyParkingDoc.exists()) {
          batch.update(companyRefToRemove, {
            taken_dp: null,
            user_company_dp: deleteField(),
          });
          if (companyParkingDoc.data().taken !== null) {
            createNotificationDp(companyParkingDoc.data().taken, "");
          }
        }
      }
    }
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while adding new community parking" };
  }
};

export const myUpdateDoc = async (
  category,
  companyName,
  newData,
  oldId = ""
) => {
  if (!companyName || !category || !newData) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }
  // console.log(newData);
  let oldTaken = null;
  let oldTaken_dp = null;
  let oldParking_dp = null;
  let oldParking = null;
  let oldDesk = null;
  let oldFuture = null;
  let oldFutureDesk = null;
  let community = false;
  if (await checkParticipate(companyName)) {
    community = true;
  }
  if (oldId !== "") {
    // Get Old taken && taken_dp from parking spot + update user to new name as parking
    if (category === "parkings") {
      const companyDocRef = doc(db, "companies", companyName);
      const docRefToGet = doc(companyDocRef, `parkings`, oldId);
      const docData = await getDoc(docRefToGet);
      if (docData.exists()) {
        oldTaken = docData.data().taken;
        oldTaken_dp = docData.data().taken_dp;
        oldParking_dp = docData.data().double_parking;
      }
    } else if (category === "desks") {
      const companyDocRef = doc(db, "companies", companyName);
      const docRefToGet = doc(companyDocRef, `desks`, oldId);
      const docData = await getDoc(docRefToGet);
      if (docData.exists()) {
        oldTaken = docData.data().taken;
      }
    } else {
      const companyDocRef = doc(db, "companies", companyName);
      const docRefToGet = doc(companyDocRef, `users`, oldId);
      const docData = await getDoc(docRefToGet);
      if (docData.exists()) {
        const oldKeys = Object.keys(docData.data());
        if (oldKeys.includes("parking")) {
          oldParking = docData.data()?.parking;
          oldFuture = oldKeys.includes("future") ? docData.data().future : null;
        }
        if (oldKeys.includes("desk")) {
          oldDesk = docData.data()?.desk;
          oldFutureDesk = oldKeys.includes("future_desk")
            ? docData.data().future_desk
            : null;
        }
      }
    }
    // If ID(email/parking_id) changed, remove old doc and add new one
    removeDoc(category, companyName, oldId);
  }
  const docId =
    category === "users"
      ? newData.email.toLowerCase().trim()
      : `${newData.level}:${
          category === "parkings" ? newData.parking_id : newData.desk_id
        }`;
  const companyDocRef = doc(db, "companies", companyName);
  const companyData = await getDoc(companyDocRef);
  const communityRef = doc(db, "parkinglots", companyData.data().parking_id);
  console.log(`${category}, ${docId}`);
  const docRefToUpdate = doc(companyDocRef, `${category}`, docId);
  const batch = writeBatch(db);
  const docData = await getDoc(docRefToUpdate);
  if (!docData.exists()) {
    try {
      if (category === "users") {
        if (!(await checkEmailExist(newData.email.toLowerCase().trim())))
          return null;
        const userCars =
          newData.cars !== undefined
            ? Array.isArray(newData.cars)
              ? newData.cars
              : (newData.cars + "")
                  .replace(/(^[,\s]+)|([,\s]+$)/g, "")
                  .includes(",")
              ? (newData.cars + "")
                  .replace(/(^[,\s]+)|([,\s]+$)/g, "")
                  .split(",")
                  .slice(0, 3)
              : [(newData.cars + "").replace(/(^[,\s]+)|([,\s]+$)/g, "")]
            : null;
        batch.set(docRefToUpdate, {
          email: newData.email.toLowerCase().trim(),
          name: newData.name,
          phone: newData.phone,
          cars: userCars,
          teams: newData.hasOwnProperty("teams") ? newData.teams : [],
          priority: newData.priority.toString(),
          parking: oldParking,
          desk: oldDesk,
          future: oldFuture,
          future_desk: oldFutureDesk,
          electric: newData.hasOwnProperty("electric")
            ? newData.electric
            : false,
          arrival_time: newData.arrival_time.toString(),
        });
        // await batch.commit();
        // Update USERS table
        const userDocRef = doc(db, "users", newData.email.toLowerCase());
        batch.set(userDocRef, {
          email: newData.email.toLowerCase(),
          name: newData.name,
          companyName: companyName,
          role: newData.hasOwnProperty("role")
            ? newData.role.toLowerCase()
            : "user",
        });
      } else if (category === "desks") {
        // If entered here there are no other desks in same company so no need to check
        batch.set(docRefToUpdate, {
          desk_id: newData.desk_id.toString(),
          level: newData.level.toString(),
          teams: newData.hasOwnProperty("teams") ? newData.teams : [],
          tags: newData.hasOwnProperty("tags") ? newData.tags : [],
          priority: newData.priority.toString(),
          taken: oldTaken,
        });
      } else {
        if (
          !(await checkParkingExist(
            companyData.data().parking_id,
            `${newData.level}:${newData.parking_id}`,
            false
          ))
        )
          return null;
        if (
          newData.double_parking !== "" &&
          newData.double_parking !== undefined
        ) {
          if (
            !(await checkParkingExist(
              companyData.data().parking_id,
              `${newData.level}:${newData.double_parking}`,
              true
            ))
          )
            return null;
        }
        batch.set(docRefToUpdate, {
          parking_id: newData.parking_id.toString(),
          level: newData.level.toString(),
          double_parking: newData.double_parking
            ? newData.double_parking
            : null,
          priority: newData.priority.toString(),
          taken: oldTaken,
          taken_dp: newData.double_parking ? oldTaken_dp : null,
          electric: newData.electric ? newData.electric : false,
        });
        if (
          community &&
          ((newData.hasOwnProperty("electric") && !newData.electric) ||
            !newData.hasOwnProperty("electric"))
        ) {
          const communityRefToupdate = doc(
            communityRef,
            "free-parkings",
            docId
          );
          batch.set(communityRefToupdate, {
            parking_id: newData.parking_id,
            level: newData.level.toString(),
            double_parking: newData.double_parking
              ? newData.double_parking
              : null,
            priority: newData.priority.toString(),
            taken: oldTaken,
            taken_dp: newData.double_parking ? oldTaken_dp : null,
            owner: companyName,
          });
        }
      }
    } catch (error) {
      console.log("error: ", error);
      throw { code: "4", message: "Error while addning doc" };
    }
  } else {
    if (category === "users") {
      const userCars =
        newData.cars !== undefined
          ? Array.isArray(newData.cars)
            ? newData.cars
            : (newData.cars + "")
                .replace(/(^[,\s]+)|([,\s]+$)/g, "")
                .includes(",")
            ? (newData.cars + "")
                .replace(/(^[,\s]+)|([,\s]+$)/g, "")
                .split(",")
                .slice(0, 3)
            : [(newData.cars + "").replace(/(^[,\s]+)|([,\s]+$)/g, "")]
          : null;
      batch.update(docRefToUpdate, {
        email: newData.email.trim(),
        name: newData.name,
        phone: newData.phone,
        cars: userCars,
        teams: newData.hasOwnProperty("teams") ? newData.teams : [],
        priority: newData.priority.toString(),
        electric: newData.hasOwnProperty("electric") ? newData.electric : false,
        arrival_time: newData.arrival_time.toString(),
      });
      // await batch.commit();
      // Update USERS table
      const userDocRef = doc(db, "users", newData.email.toLowerCase());
      batch.update(userDocRef, {
        name: newData.name,
        role: newData.hasOwnProperty("role")
          ? newData.role.toLowerCase()
          : "user",
      });
    } else if (category === "desks") {
      batch.update(docRefToUpdate, {
        desk_id: newData.desk_id.toString(),
        teams: newData.hasOwnProperty("teams") ? newData.teams : [],
        tags: newData.hasOwnProperty("tags") ? newData.tags : [],
        level: newData.level.toString(),
        priority: newData.priority.toString(),
      });
    } else {
      batch.update(docRefToUpdate, {
        parking_id: newData.parking_id.toString(),
        level: newData.level.toString(),
        double_parking: newData.double_parking ? newData.double_parking : null,
        priority: newData.priority.toString(),
        electric: newData.electric ? newData.electric : false,
      });
      if (newData.double_parking === null) {
        if (docData.data().taken_dp !== null) {
          // Remove from user that has it
          removeTakenUser(companyName, docData.data().taken_dp, null, true);
          // Remove from parking
          batch.update(docRefToUpdate, {
            taken_dp: null,
          });
        }
      }
      if (community) {
        const communityRefToupdate = doc(communityRef, "free-parkings", docId);
        const docData = await getDoc(communityRefToupdate);
        if (docData.exists()) {
          batch.update(communityRefToupdate, {
            parking_id: newData.parking_id,
            level: newData.level.toString(),
            double_parking: newData.double_parking
              ? newData.double_parking
              : null,
            priority: newData.priority.toString(),
          });
        }
      }
    }
  }
  if (oldId !== "") {
    if (category === "parkings") {
      // If parking has been given a new ID
      // Update user to new name as parking
      try {
        if (oldTaken !== null) {
          const docRefOldTaken = doc(companyDocRef, `users`, oldTaken);
          const oldTakenData = await getDoc(docRefOldTaken);
          if (oldTakenData.exists()) {
            batch.update(docRefOldTaken, {
              parking: `${newData.level}:${newData.parking_id}`,
            });
          }
        }
        if (oldTaken_dp !== null) {
          const docRefOldTaken_dp = doc(companyDocRef, `users`, oldTaken_dp);
          const oldTaken_dpData = await getDoc(docRefOldTaken_dp);
          if (oldTaken_dpData.exists()) {
            batch.update(docRefOldTaken_dp, {
              parking: newData.double_parking
                ? `${newData.level}:${newData.double_parking}`
                : null,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else if (category === "desks") {
      try {
        if (oldTaken !== null) {
          const docRefOldTaken = doc(companyDocRef, `users`, oldTaken);
          const oldTakenData = await getDoc(docRefOldTaken);
          if (oldTakenData.exists()) {
            batch.update(docRefOldTaken, {
              desk: `${newData.level}:${newData.desk_id}`,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      // If user has been given a new email
      // Update user to new name as user
      try {
        if (oldDesk !== null) {
          const docRefDesk = doc(companyDocRef, `desks`, oldDesk);
          const deskData = await getDoc(docRefDesk);
          if (deskData.exists()) {
            batch.update(docRefToUpdate, {
              taken: newData.email.trim(),
            });
          }
        }
        if (oldParking !== null) {
          const docRefParking = doc(companyDocRef, `parkings`, oldParking);
          const parkingData = await getDoc(docRefParking);
          if (parkingData.exists()) {
            // taken
            batch.update(docRefToUpdate, {
              taken: newData.email.trim(),
            });

            if (community) {
              const communityRefToupdate = doc(
                communityRef,
                "free-parkings",
                oldParking
              );
              const docData = await getDoc(communityRefToupdate);
              if (docData.exists()) {
                batch.update(communityRefToupdate, {
                  taken: newData.email.trim(),
                });
              }
            }
          } else {
            // taken_dp
            const q = query(
              collection(companyDocRef, "parkings"),
              where("double_parking", "==", oldParking.split(":"[1])),
              where("level", "==", oldParking.split(":"[0]))
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const parkingToUpdate = doc(
                companyDocRef,
                "parkings",
                `${querySnapshot.docs[0].data().level}:${
                  querySnapshot.docs[0].data().parking_id
                }`
              );
              batch.update(parkingToUpdate, {
                taken_dp: newData.email.trim(),
              });
              if (
                community &&
                ((newData.hasOwnProperty("electric") && !newData.electric) ||
                  !newData.hasOwnProperty("electric"))
              ) {
                const communityRefToupdate = doc(
                  communityRef,
                  "free-parkings",
                  `${querySnapshot.docs[0].data().level}:${
                    querySnapshot.docs[0].data().parking_id
                  }`
                );
                const docData = await getDoc(communityRefToupdate);
                if (docData.exists()) {
                  batch.update(communityRefToupdate, {
                    taken_dp: newData.email.trim(),
                  });
                }
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while updating doc" };
  }
  return "Updated Doc";
};

export const removeDoc = async (category, companyName, docId) => {
  if (!companyName || !category || !docId) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const DocRefToRemove = doc(companyDocRef, `${category}`, docId);
  const batch = writeBatch(db);
  const docToRemove = await getDoc(DocRefToRemove);
  if (docToRemove.exists()) {
    try {
      await deleteDoc(DocRefToRemove);
    } catch (error) {
      console.log("error: ", error);
      throw { code: "4", message: "Error while removing doc" };
    }
  } else {
    throw { code: "3", message: "Doc was not found" };
  }
  if (category === "users") {
    removeTakenUser(companyName, docId, null, null);
    const usersDocRef = doc(db, "users", docId);
    const usersDocToRemove = await getDoc(usersDocRef);
    if (usersDocToRemove.exists()) {
      try {
        await deleteDoc(usersDocRef);
      } catch (error) {
        console.log("error: ", error);
        throw { code: "4", message: "Error while removing doc" };
      }
    } else {
      throw { code: "3", message: "Doc was not found" };
    }
  } else if (category === "desks") {
    try {
      checkLevelsRemoveMap(companyName, docToRemove.data().level, category);
    } catch (e) {
      console.log("error removing map");
    }
    if (docToRemove.data().taken !== null) {
      const DocRefToUpdate = doc(
        companyDocRef,
        `users`,
        docToRemove.data().taken
      );
      const DocToUpdate = await getDoc(DocRefToUpdate);
      if (DocToUpdate.exists()) {
        batch.update(DocRefToUpdate, {
          desk: null,
        });
      }
    }
  } else {
    try {
      checkLevelsRemoveMap(companyName, docToRemove.data().level, category);
    } catch (e) {
      console.log("error removing map");
    }
    if (await checkParticipate(companyName)) {
      const companyData = await getDoc(companyDocRef);
      const plDoc = doc(db, `parkinglots`, companyData.data().parking_id);
      const freeParkingsDoc = doc(plDoc, `free-parkings`, docId);
      const communityParkingToRemove = await getDoc(freeParkingsDoc);
      if (communityParkingToRemove.exists()) {
        try {
          await deleteDoc(freeParkingsDoc);
        } catch (error) {
          console.log("error: ", error);
          throw { code: "4", message: "Error while removing doc" };
        }
        const parkingData = communityParkingToRemove.data();
        if (parkingData.taken !== null && parkingData.user_company != null) {
          const userCompanyRef = doc(db, "companies", parkingData.user_company);
          const DocRefToUpdate = doc(
            userCompanyRef,
            `users`,
            parkingData.taken
          );
          const DocToUpdate = await getDoc(DocRefToUpdate);
          if (DocToUpdate.exists()) {
            batch.update(DocRefToUpdate, {
              parking: null,
              parking_owner: deleteField(),
            });
          }
        }
        if (
          parkingData.taken_dp !== null &&
          parkingData.user_company_dp != null
        ) {
          const userCompanyRef_dp = doc(
            db,
            "companies",
            parkingData.user_company_dp
          );
          const DocRefToUpdate_dp = doc(
            userCompanyRef_dp,
            `users`,
            parkingData.taken_dp
          );
          const DocToUpdate_dp = await getDoc(DocRefToUpdate_dp);
          if (DocToUpdate_dp.exists()) {
            batch.update(DocRefToUpdate_dp, {
              parking: null,
              parking_owner: deleteField(),
            });
          }
        }
      }
    } else {
      if (docToRemove.data().taken !== null) {
        const DocRefToUpdate = doc(
          companyDocRef,
          `users`,
          docToRemove.data().taken
        );
        const DocToUpdate = await getDoc(DocRefToUpdate);
        if (DocToUpdate.exists()) {
          batch.update(DocRefToUpdate, {
            parking: null,
            parking_owner: deleteField(),
          });
        }
      }
      if (docToRemove.data().taken_dp !== null) {
        const DocRefToUpdate_dp = doc(
          companyDocRef,
          `users`,
          docToRemove.data().taken_dp
        );
        const DocToUpdate_dp = await getDoc(DocRefToUpdate_dp);
        if (DocToUpdate_dp.exists()) {
          batch.update(DocRefToUpdate_dp, {
            parking: null,
            parking_owner: deleteField(),
          });
        }
      }
    }
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while updating doc" };
  }
  return "Doc has been removed";
};

export const checkEmail = async (email) => {
  const userDocRef = doc(db, "users", email.trim());
  const userSnapShot = await getDoc(userDocRef);
  if (!userSnapShot.exists()) {
    return 0; // Email does not exist
  }
  if (
    userSnapShot.data().role.toLowerCase() === "admin" ||
    userSnapShot.data().role.toLowerCase() === "superadmin"
  ) {
    return 2; // Success
  }
  return 1; // Not admin
};

export const checkEmailExist = async (email) => {
  const userDocRef = doc(db, "users", email.trim());
  const userSnapShot = await getDoc(userDocRef);
  if (userSnapShot.exists()) {
    if (userSnapShot.data().hasOwnProperty("companyName")) return false; // Email exists - not free to use
    return true;
  }
  return true;
};

export const checkParkingExist = async (plid, parkingId, isDp) => {
  // check if parking id exists inside parking lot
  // go through all companys in pl and check if they have parking with same id
  const parkingLotRef = doc(db, "parkinglots", plid);
  const plSnapShot = await getDoc(parkingLotRef);
  if (!plSnapShot.exists()) {
    return 0; // pl does not exist = ERROR
  }
  // console.log(parkingId);
  // console.log(plSnapShot.data().companies);
  let toReturn = true;
  if (!isDp) {
    await Promise.all(
      Object.keys(plSnapShot.data().companies).map(async (company) => {
        // console.log(company);
        const companyDocRef = doc(db, "companies", company);
        const docRefToCheck = doc(companyDocRef, `parkings`, parkingId);
        const docData = await getDoc(docRefToCheck);
        if (docData.exists()) {
          toReturn = false;
        }
      })
    );
  } else {
    await Promise.all(
      Object.keys(plSnapShot.data().companies).map(async (company) => {
        const companyDocRef = doc(db, "companies", company);
        const q = query(
          collection(companyDocRef, "parkings"),
          where("double_parking", "==", parkingId.split(":"[1])),
          where("level", "==", parkingId.split(":"[0]))
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) toReturn = false;
      })
    );
  }
  // console.log(toReturn);
  return toReturn;
};

export const getImage = async (location, level) => {
  try {
    let url = "";
    const res = await listAll(ref(storage, location));
    await Promise.all(
      res.items.map((itemRef) => {
        if (itemRef.name.split(".")[0] === level) {
          url = itemRef.fullPath;
        }
      })
    );
    const ImageURL = await getDownloadURL(ref(storage, url));
    return ImageURL;
  } catch (error) {
    return null;
  }
};

export const setMapImage = async (
  location,
  file,
  mapRef,
  level,
  companyName
) => {
  const res = await listAll(ref(storage, mapRef));
  await Promise.all(
    res.items.map((itemRef) => {
      if (itemRef.name.split(".")[0] === level) {
        deleteObject(ref(storage, itemRef.fullPath))
          .then(() => {
            // File deleted successfully
            return "deleted img";
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
            return error;
          });
      }
    })
  );
  const storageRef = ref(storage, location);
  await uploadBytesResumable(storageRef, file);
  const companyDocRef = doc(db, "companies", companyName);
  console.log(mapRef.slice(0, 4));
  if (mapRef.slice(0, 4) === "maps") {
    console.log(level);
    await updateDoc(companyDocRef, {
      [`maps-${location.split("/")[2]}.` + level]: location.split("/")[3],
    });
  } else {
    console.log("inside");
    console.log(location.split("/")[1]);
    await updateDoc(companyDocRef, {
      logo: location.split("/")[1],
    });
  }
  const ImageURL = await getDownloadURL(ref(storage, location));
  return ImageURL;
};

export const removeMapImage = async (location, level) => {
  // const companyDocRef = doc(db, "companies", companyName);
  // await updateDoc(companyDocRef, {
  //   [`maps-${category}.` + level]: null,
  // });
  const storageRef = ref(storage, location);
  let url = "";
  const res = await listAll(storageRef);
  await Promise.all(
    res.items.map((itemRef) => {
      if (itemRef.name.split(".")[0] === level) {
        url = itemRef.fullPath;
      }
    })
  );
  console.log("map delelteetete");
  console.log(url);
  // Delete the file
  if (url !== "" && url) {
    deleteObject(ref(storage, url))
      .then(() => {
        // File deleted successfully
        return "deleted img";
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
        return error;
      });
  }
};

export const checkLevelsRemoveMap = async (companyName, level, category) => {
  const companyRef = doc(db, "companies", companyName);
  const q = query(
    collection(companyRef, `${category}`),
    where("level", "==", level)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    // If no more parkings from the level exist remove map
    removeMapImage(`maps/${companyName}/${category}`, level);
  }
};

/******************/
/*** Community ****/
/******************/

export const checkParticipate = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  if (!companySnapshot.exists()) {
    console.log(`Error in company data`);
    return;
  }
  const plDoc = doc(db, `parkinglots`, companySnapshot.data().parking_id);
  const plSnapshot = await getDoc(plDoc);
  if (!plSnapshot.exists()) {
    console.log(`Parking lot id doesnt exist`);
    return;
  }
  if (!checkTime(plSnapshot.data().create_time)) return;
  const communityRef = doc(
    db,
    "parkinglots",
    companySnapshot.data().parking_id
  );
  const q = query(collection(communityRef, "free-parkings"), limit(1));
  const querySnapshot = await getDocs(q);
  // console.log(querySnapshot);
  if (querySnapshot.empty) {
    // console.log(`Parking lot doesn't have free parkings`);
    return;
  }
  // console.log(companySnapshot.data().community_participation);
  return companySnapshot.data().community_participation;
};

export const checkParticipateSettings = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  if (!companySnapshot.exists()) {
    console.log(`Error in company data`);
    return;
  }
  // console.log(companySnapshot.data().community_participation);
  return companySnapshot.data().community_participation;
};

// Should i maybe remove check time? it might be erelevent because the free-parkings collection is what trully decides if community is active
export const checkTime = (createTime) => {
  // check time agreed on in parking lot to move to free-parkings
  const time = new Date();
  const hours = time.getHours();
  const minutes = time.getMinutes();
  if (hours >= Number(createTime.split(":")[0]) && hours < 20) {
    // 20
    if (
      hours === Number(createTime.split(":")[0]) &&
      minutes < Number(createTime.split(":")[1])
    )
      return false;
    return true;
  }
  return false;
};

export const getCommunityList = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  if (!companySnapshot.exists()) {
    console.log(`Error in company data`);
    return;
  }
  const plId = companySnapshot.data().parking_id;
  const plDoc = doc(db, `parkinglots`, plId);
  const plSnapshot = await getDoc(plDoc);
  if (!plSnapshot.exists()) {
    console.log(`Parking lot id doesnt exist`);
    return;
  }
  const companyList = [];
  Object.keys(plSnapshot.data().companies).map((company) => {
    if (plSnapshot.data().companies[company])
      companyList.push(company.charAt(0).toUpperCase() + company.slice(1));
  });
  // console.log([companyList, plSnapshot.data().name]);
  return [companyList, plSnapshot.data().name];
};

/******************/
/*** WorkStation **/
/******************/

export const getDesksDashboardDataFromDate = async (companyName, date) => {
  if (companyName === null || date === null) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const dateDoc = doc(companyDocRef, `report-month-desks`, date);
  const docData = await getDoc(dateDoc);
  if (!docData.exists()) {
    console.log(`Date doesnt exist in report`);
    return null;
  }
  let finalData = {};
  let taken = 0;
  let level = {};
  let priority = {};
  for (const [key, desk] of Object.entries(docData.data())) {
    if (desk.taken) {
      taken++;
    }
    if (desk.level in level) {
      level[desk.level].all = level[desk.level].all + 1;
      if (desk.taken) level[desk.level].taken = level[desk.level].taken + 1;
    } else {
      level[desk.level] = { all: 1, taken: 0 };
      if (desk.taken) level[desk.level].taken = level[desk.level].taken + 1;
    }
    if (desk.priority in priority) {
      priority[desk.priority].all = priority[desk.priority].all + 1;
      if (desk.taken)
        priority[desk.priority].taken = priority[desk.priority].taken + 1;
    } else {
      priority[desk.priority] = { all: 1, taken: 0 };
      if (desk.taken)
        priority[desk.priority].taken = priority[desk.priority].taken + 1;
    }
  }
  finalData = {
    date,
    all: Object.keys(docData.data()).length,
    taken: taken,
    level,
    priority,
  };
  return finalData;
};

export const getDeskDay = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companyDesks = collection(companyDocRef, `desks`);
  const q = query(companyDesks);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 0) {
    console.log(`No desks`);
    return [];
  }
  let dataMap = [];
  dataMap = await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      return {
        id: `${docSnapshot.data().level}:${docSnapshot.data().desk_id}`,
        level: docSnapshot.data().level,
        desk_id: docSnapshot.data().desk_id,
        taken: docSnapshot.data().taken,
        priority: docSnapshot.data().priority,
        teams: docSnapshot.data().teams,
      };
    })
  );
  dataMap.sort((a, b) => {
    try {
      if (a.level.length > b.level.length) return 1;
      else if (a.level.length < b.level.length) return -1;
      else {
        if (a.level > b.level) return 1;
        else if (a.level < b.level) return -1;
        else {
          if (a.desk_id.length > b.desk_id.length) return 1;
          else if (a.desk_id.length < b.desk_id.length) return -1;
          else {
            if (a.desk_id > b.desk_id) return 1;
            else if (a.desk_id < b.desk_id) return -1;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return a.id > b.id ? 1 : -1;
  });
  console.log(dataMap);
  return dataMap;
};

export const setTakenDesk = async (companyName, newDoc) => {
  if (companyName === null || newDoc === null) {
    console.log("error: data not received");
    throw { code: "655", message: "data not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  // Check if company locked
  const companyDoc = await getDoc(companyDocRef);
  if (companyDoc.data().hasOwnProperty("lock") && companyDoc.data().lock) {
    console.log(
      "Database locked for evening maintenance, please try again later"
    );
    throw {
      code: "655",
      message:
        "Database locked for evening maintenance, please try again later",
    };
  }
  const deskRefToUpdate = doc(
    companyDocRef,
    "desks",
    `${newDoc.level}:${newDoc.desk_id}`
  );
  const batch = writeBatch(db);
  const deskDoc = await getDoc(deskRefToUpdate);
  if (deskDoc.exists()) {
    // Check if already taken by user and remove that user from desk
    if (deskDoc.data().taken !== null) {
      await removeTakenUserDesk(
        companyName,
        deskDoc.data().taken,
        `${newDoc.level}:${newDoc.desk_id}`
      );
    }
    if (newDoc.taken && newDoc.taken !== "" && newDoc.taken !== "guest") {
      await checkOtherDesks(
        companyName,
        newDoc.taken.toLowerCase(),
        `${newDoc.level}:${newDoc.desk_id}`
      );
    }
    try {
      batch.update(deskRefToUpdate, {
        taken:
          newDoc.taken && newDoc.taken !== ""
            ? newDoc.taken.toLowerCase()
            : null,
      });
      // Check if 'waitlist_desks" isnt empty before creating:
      if (
        companyDoc.data().hasOwnProperty("waitlist_desks") &&
        companyDoc.data().waitlist_desks.size > 0
      ) {
        if (!newDoc.taken || newDoc.taken === "") {
          batch.set(doc(db, "notify_waitlist_desk", companyName), {
            desk_id: `${newDoc.level}:${newDoc.desk_id}`,
          });
        }
      }
      await batch.commit();
    } catch (error) {
      console.log("error: ", error);
      throw { code: "4", message: "Error while updating doc" };
    }
  } else {
    throw { code: "3", message: "Desk with this id doesn`t exists" };
  }
  try {
    await setTakenUserDesk(companyName, newDoc);
  } catch (e) {
    console.log(e);
    throw { code: "5", message: "Error updating user doc" };
  }
  if (newDoc.taken !== "" && newDoc.taken !== null) {
    createNotificationDesk(
      newDoc.taken?.toLowerCase(),
      `${newDoc.level}:${newDoc.desk_id}`
    );
  }
  return "Taken has been updated";
};

export const removeTakenUserDesk = async (companyName, userToRemove, desk) => {
  const companyDocRef = doc(db, "companies", companyName);
  const userRefToRemove = doc(companyDocRef, "users", userToRemove.trim());
  const batch = writeBatch(db);
  const userDoc = await getDoc(userRefToRemove);
  if (userDoc.exists()) {
    try {
      batch.update(userRefToRemove, {
        desk: null,
      });
      console.log(userDoc.data().desk);
      // if (userDoc.data().desk !== desk) {
      //   console.log(userToRemove);
      createNotificationDesk(userToRemove, "null");
      // }
    } catch (error) {
      console.log("error: ", error);
      throw { code: "4", message: "Error while updating user" };
    }
  } else {
    return "User with this email doesnt exist";
  }
  const desksDoc = doc(companyDocRef, `desks`, userDoc.data().desk);
  const deskToUpdate = await getDoc(desksDoc);
  if (deskToUpdate.exists()) {
    batch.update(desksDoc, {
      taken: null,
    });
  } else {
    console.log("Error while updating user");
    return "users desk downst exist";
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while updating user" };
  }
  return "Users desk has been updated";
};

export const checkOtherDesks = async (companyName, userToCheck, desk) => {
  const companyDocRef = doc(db, "companies", companyName);
  const userRefToCheck = doc(companyDocRef, "users", userToCheck.trim());
  const batch = writeBatch(db);
  const userDoc = await getDoc(userRefToCheck);
  if (userDoc.exists()) {
    if (!userDoc.data().desk) return;
    const deskRefToRemove = doc(companyDocRef, "desks", userDoc.data().desk);
    const deskDoc = await getDoc(deskRefToRemove);
    if (deskDoc.exists()) {
      try {
        batch.update(deskRefToRemove, {
          taken: null,
        });
      } catch (error) {
        console.log("error: ", error);
        throw { code: "4", message: "Error while updating desk" };
      }
    }
    try {
      await batch.commit();
    } catch (error) {
      console.log("error: ", error);
      throw { code: "4", message: "Error while updating desk" };
    }
  } else {
    return "User with this email doesnt exist";
  }
  console.log("user has been removed from other desk");
  return "user has been removed from other desk";
};

export const setTakenUserDesk = async (companyName, newDoc) => {
  if (newDoc.taken) {
    const companyDocRef = doc(db, "companies", companyName);
    const batch = writeBatch(db);
    const userRefToUpdate = doc(companyDocRef, "users", newDoc.taken.trim());
    const usersDoc = await getDoc(userRefToUpdate);
    if (usersDoc.exists()) {
      try {
        batch.update(userRefToUpdate, {
          desk: `${newDoc.level}:${newDoc.desk_id}`,
        });
      } catch (error) {
        console.log("error: ", error);
        throw { code: "4", message: "Error while updating user" };
      }
    }
    await batch.commit();
    return "Users parking has been updated";
  }
};

/********************/
/* WorkStation Time */
/********************/

export const removeTakenDeskTime = async (
  companyName,
  userToRemove,
  deskId,
  time
) => {
  const companyDocRef = doc(db, "companies", companyName);
  const companyDoc = await getDoc(companyDocRef);
  const batch = writeBatch(db);
  if (userToRemove !== "guest") {
    const userRefToRemove = doc(companyDocRef, "users", userToRemove.trim());
    const userDoc = await getDoc(userRefToRemove);
    if (userDoc.exists()) {
      try {
        batch.update(userRefToRemove, {
          [`desk.${time}`]: deleteField(),
        });
      } catch (error) {
        console.log("error: ", error);
        throw { code: "4", message: "Error while updating user" };
      }
    } else {
      return false;
    }
  }
  const desksDoc = doc(companyDocRef, `desks`, deskId);
  const deskToUpdate = await getDoc(desksDoc);
  if (deskToUpdate.exists()) {
    batch.update(desksDoc, {
      [`taken.${time}`]: deleteField(),
    });
  } else {
    console.log("Error while updating user");
    return false;
  }
  const q = query(
    collection(companyDocRef, "desk_reservations"),
    where("timeString", "==", time),
    where("desk", "==", deskId),
    where("user", "==", userToRemove)
  );
  const docsToRemove = await getDocs(q);
  if (docsToRemove.empty) {
    console.log("Error while releasing desk - No doc found in docs to remove");
    return false;
  }
  const resDoc = doc(
    companyDocRef,
    `desk_reservations`,
    docsToRemove.docs[0].id
  );
  batch.delete(resDoc);
  // Check if 'waitlist_desks" isnt empty before creating:
  if (
    companyDoc.data().hasOwnProperty("waitlist_desks") &&
    companyDoc.data().waitlist_desks.size > 0
  ) {
    batch.set(doc(db, "notify_waitlist_desk", companyName), {
      desk_id: deskId,
      startTime: docsToRemove.docs[0].get("startTime"),
      endTime: docsToRemove.docs[0].get("endTime"),
    });
  }
  try {
    await batch.commit();
    createNotificationDesk(userToRemove, "null");
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while updating user" };
  }
  return true;
};

export const reserveDeskTime = async (
  companyName,
  userId,
  deskId,
  startTime,
  endTime,
  timeString
) => {
  const companyDocRef = doc(db, "companies", companyName);
  const q1 = query(
    collection(companyDocRef, "desk_reservations"),
    where("desk", "==", deskId),
    where("startTime", ">=", startTime),
    where("startTime", "<", endTime)
  );
  const q2 = query(
    collection(companyDocRef, "desk_reservations"),
    where("desk", "==", deskId),
    where("endTime", ">", startTime),
    where("endTime", "<=", endTime)
  );
  const q3 = query(
    collection(companyDocRef, "desk_reservations"),
    where("desk", "==", deskId),
    where("endTime", ">", startTime)
  );
  const resDocs1 = await getDocs(q1);
  const resDocs2 = await getDocs(q2);
  const resDocs3 = await getDocs(q3);
  if (!resDocs1.empty || !resDocs2.empty) {
    // The desk is taken in this time or part of it
    console.log("time is taken1/2");
    return false;
  } else if (!resDocs3.empty) {
    const result = resDocs3.docs.find((doc) => {
      if (startTime.getTime() / 1000 < doc["startTime"]) {
        return true;
      }
    });
    console.log(result);
    if (result === undefined) {
      console.log("time is taken3");
      return false;
    }
    // The desk is taken in this time or part of it
  }
  const batch = writeBatch(db);
  if (userId !== "guest") {
    const userRef = doc(companyDocRef, "users", userId.trim());
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      batch.update(userRef, {
        [`desk.${timeString}`]: deskId,
      });
    } else {
      return false;
    }
  }
  const desksDoc = doc(companyDocRef, `desks`, deskId);
  const deskToUpdate = await getDoc(desksDoc);
  if (deskToUpdate.exists()) {
    batch.update(desksDoc, {
      [`taken.${timeString}`]: userId,
    });
  } else {
    console.log("Error while updating user");
    return false;
  }
  const resDoc = doc(
    companyDocRef,
    `desk_reservations`,
    `${startTime}-${endTime}-${deskId}`
  );
  batch.set(resDoc, {
    desk: deskId,
    user: userId,
    startTime: startTime,
    endTime: endTime,
    timeString: timeString,
  });
  try {
    await batch.commit();
    createNotificationDesk(userId, deskId);
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while updating user" };
  }
  return true;
};

/******************/
/**** Company *****/
/******************/

export const checkCompanyParticipation = async (companyName) => {
  if (!companyName) {
    console.log("error: company name not received");
    throw { code: "655", message: "company name not received" };
  }
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  if (!companySnapshot.exists()) {
    console.log(`Error in company data`);
    return;
  }
  if (companySnapshot.data().parkings && companySnapshot.data().desks) {
    // const parkingQ = query(collection(companyDocRef, "parkings"), limit(1));
    // const parkingQuerySnapshot = await getDocs(parkingQ);
    // const deskQ = query(collection(companyDocRef, "desks"), limit(1));
    // const deskQuerySnapshot = await getDocs(deskQ);
    // if (!parkingQuerySnapshot.empty && !deskQuerySnapshot.empty) {
    return 0;
    // } else if (!deskQuerySnapshot.empty) {
    //   return 2;
    // } else {
    //   return 1;
    // }
  } else if (companySnapshot.data().parkings) {
    // const parkingQ = query(collection(companyDocRef, "parkings"), limit(1));
    // const parkingQuerySnapshot = await getDocs(parkingQ);
    // if (!parkingQuerySnapshot.empty) {
    return 1;
    // }
  } else {
    // const deskQ = query(collection(companyDocRef, "desks"), limit(1));
    // const deskQuerySnapshot = await getDocs(deskQ);
    // if (!deskQuerySnapshot.empty) {
    return 2;
    // }
  }
  // should never reach here
  return null;
};

export const getNotificationDataStore = async (companyName) => {
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  return {
    switch: companySnapshot.data().evening_notification ?? true,
    friday: companySnapshot.data().friday ?? false,
    const: companySnapshot.data().const ?? false,
    time: companySnapshot.data().morning_notification ?? "8",
    arrival_time: companySnapshot.data().arrive_time ?? "9",
  };
};

export const updateCompany = async (companyName, category, update) => {
  const companyDocRef = doc(db, "companies", companyName);
  const batch = writeBatch(db);
  try {
    if (category === "evening") {
      batch.update(companyDocRef, {
        evening_notification: update,
      });
    } else if (category === "friday") {
      batch.update(companyDocRef, {
        friday: update,
      });
    } else if (category === "const") {
      batch.update(companyDocRef, {
        const: update,
      });
    } else if (category === "morning") {
      batch.update(companyDocRef, {
        morning_notification: update,
      });
    } else if (category === "ev") {
      batch.update(companyDocRef, {
        ev_time: update,
      });
    } else if (category === "arrival") {
      batch.update(companyDocRef, {
        arrive_time: update,
      });
    } else {
      batch.update(companyDocRef, {
        proximity_distance: update,
      });
    }
    await batch.commit();
  } catch (error) {
    console.log("error: ", error);
    throw { code: "4", message: "Error while updating company data" };
  }
};

export const checkUseProximity = async (companyName) => {
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  if (!companySnapshot.exists()) {
    console.log(`Error in company data`);
    return;
  }
  if (companySnapshot.data().proximity) {
    return companySnapshot.data().proximity_distance;
  }
  return false;
};

export const checkUsesTime = async (companyName) => {
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  if (!companySnapshot.exists()) {
    console.log(`Error in company data`);
    return;
  }
  if (companySnapshot.data().time) {
    return companySnapshot.data().time;
  }
  return false;
};

export const checkHasElectric = async (companyName) => {
  const companyDocRef = doc(db, "companies", companyName);
  const q = query(
    collection(companyDocRef, "parkings"),
    where("electric", "==", true)
  );
  const electricParkings = await getDocs(q);
  if (electricParkings.empty) {
    console.log(`No electric parkings in company`);
    return false;
  }
  return true;
};

export const checkHasDp = async (companyName) => {
  const companyDocRef = doc(db, "companies", companyName);
  const q = query(
    collection(companyDocRef, "parkings"),
    where("double_parking", "!=", null),
    where("electric", "==", false)
  );
  const doubleParkings = await getDocs(q);
  if (doubleParkings.empty) {
    console.log(`No double parkings in company`);
    return false;
  }
  return true;
};

export const getEvTime = async (companyName) => {
  const companyDocRef = doc(db, "companies", companyName);
  const companySnapshot = await getDoc(companyDocRef);
  if (!companySnapshot.exists()) {
    console.log(`Error in company data`);
    return;
  }
  if (companySnapshot.data().hasOwnProperty("ev_time")) {
    return companySnapshot.data().ev_time;
  }
  return false;
};

/******************/
/* Notifications **/
/******************/

export const createNotification = async (email, parking) => {
  const notificationsDocRef = await addDoc(collection(db, "notifications"), {
    email: email,
    parking: parking,
  });
  console.log("Notification added to firestore");
};

export const createNotificationDesk = async (email, desk) => {
  const notificationsDocRef = await addDoc(
    collection(db, "notifications_desk"),
    {
      email: email,
      desk: desk,
    }
  );
  console.log("Notification desk added to firestore");
};

export const createNotificationDp = async (email, dp_user) => {
  if (
    email === null ||
    email === undefined ||
    email === "" ||
    dp_user === null ||
    dp_user === undefined ||
    dp_user === ""
  ) {
    return;
  }
  const notificationsDocRef = await addDoc(collection(db, "notifications_dp"), {
    email: email,
    dp_user: dp_user,
  });
  console.log("Notification DP added to firestore");
};
