import "../../App.css";
import { useEffect } from "react";
import LandingCta from "../cta/cta.component";
import LandingHero from "../hero/hero.component";
import LandingPhone from "../phone-demo/phone.component";
import LandingAdvantages from "../advantages/advantages.component";
import LandingAbout from "../about/about.component";
import Directory from "../directory/directory.component";
import { Helmet } from "react-helmet";
import LandingPlatform from "../platform/platform.component";

function LandingPage() {
  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Plas - מערכת חכמה לניהול משאבים משותפים בחברות</title>
        <meta
          name="description"
          content="PLAS (פלאס) היא מערכת המספקת ניהול ואופטימיזציה מיטבית של חניות ועמדות העבודה החמות של הארגונים בבנייני המשרדים"
        />
        <meta
          property="og:title"
          content="Plas - מערכת חכמה לניהול משאבים משותפים בחברות"
        />
        <meta
          property="og:description"
          content="PLAS (פלאס) היא מערכת המספקת ניהול ואופטימיזציה מיטבית של חניות ועמדות העבודה החמות של הארגונים בבנייני המשרדים"
        />
        <meta property="og:url" content="https://plas.co.il" />
        <meta
          name="twitter:title"
          content="Plas - מערכת חכמה לניהול משאבים משותפים בחברות"
        />
        <meta
          name="twitter:description"
          content="PLAS (פלאס) היא מערכת המספקת ניהול ואופטימיזציה מיטבית של חניות ועמדות העבודה החמות של הארגונים בבנייני המשרדים"
        />
      </Helmet>
      <div className="landing-page">
        <main>
          {/* HERO SECTION */}
          <LandingHero />

          {/* ABOUT SECTION */}
          <LandingAbout />

          {/* PHONE SECTION */}
          <LandingPhone />

          {/* PLATFORM SECTION */}
          <LandingPlatform />

          {/* DIRECTORY SECTION */}
          <Directory />

          {/* ADVANTAGES SECTION */}
          <LandingAdvantages />

          {/* CTA SECTION */}
          <LandingCta />
        </main>
      </div>
    </div>
  );
}

export default LandingPage;
