import { compose, applyMiddleware } from "redux";
import { legacy_createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loggerMiddleware from "./middleware/logger";
// import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import { rootReducer } from "./root-reducer";
import { rootSaga } from "./root-saga";

// root-reducer

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["user"],
  //   whitelist: ["cart", "wishlist", "search"],
};

const sagaMiddleware = createSagaMiddleware();
// const sagaMiddleware = createSagaMiddleware({
//   onError: (err) => {
//     store.dispatch({ type: 'ERROR', payload: err })
//   }
// });

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [
  process.env.NODE_ENV !== "production" && logger,
  sagaMiddleware, // thunk
].filter(Boolean);

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const composedEnhancers = composeEnhancer(applyMiddleware(...middleWares));

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: [logger],
// });
export const store = legacy_createStore(
  persistedReducer,
  undefined,
  composedEnhancers
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
