import Button from "../../components/button/button.component";
import "./hero.styles.scss";
import { useInView } from "react-intersection-observer";
// import { ReactComponent as Logo } from "../assets/logo_nobg.svg";
// import { ReactComponent as Logo } from "../../assets/Plas-logo-2-white-big.svg";
import { ReactComponent as Logo } from "../../assets/Plas-logo-white-big.svg";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";

function LandingHero() {
  const [heroRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [enterRef, EnterView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    const sectionHero = document.querySelector(".section-hero");
    const headerEl = document.querySelector(".header");
    const obs = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        if (ent.isIntersecting === false) {
          headerEl.classList.add("sticky");
          sectionHero.classList.add("sticky-margin");
        }

        if (ent.isIntersecting === true) {
          headerEl.classList.remove("sticky");
          sectionHero.classList.remove("sticky-margin");
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-80px",
      }
    );
    if (sectionHero) {
      obs.observe(sectionHero);
    }
  }, [EnterView]);

  // const startObserver = () => {
  //   // Sticky nav
  //   console.log("inside");
  //   const sectionHero = document.querySelector(".section-hero");
  //   const headerEl = document.querySelector(".header");
  //   const obs = new IntersectionObserver(
  //     function (entries) {
  //       const ent = entries[0];
  //       if (ent.isIntersecting === false) {
  //         headerEl.classList.add("sticky");
  //         sectionHero.classList.add("sticky-margin");
  //       }

  //       if (ent.isIntersecting === true) {
  //         headerEl.classList.remove("sticky");
  //         sectionHero.classList.remove("sticky-margin");
  //       }
  //     },
  //     {
  //       root: null,
  //       threshold: 0,
  //       rootMargin: "-80px",
  //     }
  //   );
  //   if (sectionHero) {
  //     obs.observe(sectionHero);
  //   }
  // };

  return (
    <section
      ref={enterRef}
      className="section-hero"
      id="hero"
      // onLoad={startObserver}
    >
      <div ref={heroRef} className={`hero ${inView ? "animate" : ""}`}>
        <div className="hero-text-box">
          {/* <img
            className="hero-logo"
            alt="Plas Logo"
            src={require("../assets/webp/car_in_parking_1.webp")}
          /> */}
          <Logo className="hero-logo" alt="Plas Logo" />
          <h1 className="heading-primary hero-heading">
            מערכת חכמה לניהול משאבים משותפים בחברות
          </h1>
          <p className="hero-description">
            <b>PLAS</b> היא מערכת המספקת ניהול ואופטימיזציה מיטבית של חניות
            ועמדות עבודה חמות במשרדים
            <br></br>ובמקביל חוסכת לכם כסף!
          </p>
          <div className="flex-row hero-button-container">
            <a href="#cta" className="hero-link">
              <Button className="hero-button inverted hero-main-btn">
                הצטרפו אלינו
              </Button>
            </a>
            <a href="#about" className="hero-link">
              <Button className="hero-button inverted-outlined">
                פרטים נוספים &darr;
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingHero;
