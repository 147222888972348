import { randomId } from "@mui/x-data-grid-generator";

export async function getDataFromSheet(sheetId) {
  // https://stackoverflow.com/questions/31765773/converting-google-visualization-query-result-into-javascript-array
  // https://developers.google.com/chart/interactive/docs/dev/implementing_data_source#responseformat

  if (sheetId === "" || sheetId === null) {
    return null;
  }

  const spreadsheetId = "test", //1lipvFbBqi0PhyeNMik6rhgYnBRlg4aJFX6LdjXvwBTQ
    response = await fetch(
      `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:json`
    ),
    result = await response.text(),
    json = JSON.parse(
      result.replace(
        /.*google.visualization.Query.setResponse\({(.*?)}\);?/s,
        "{$1}"
      )
    );

  console.log(json);

  // `table.cols` element contains headings
  // we will use them to build our data array
  const headings = json.table.cols.map((item) => item.label);

  console.log(headings);
  var stop = 0;
  for (var i = 0; i < json.table.rows.length; i++) {
    if (json.table.rows[i].c[0] === null) {
      stop = i;
      break;
    }
  }
  var newRows = json.table.rows.slice(0, stop);
  // console.log(newRows);

  // data of each row is associated to the headings
  let data = newRows.map((item, i) => {
    // console.log(item);
    if (i === 0 && headings[0] === "") {
      item.c.forEach((cell, idx) => {
        headings[idx] = cell.v.toLowerCase();
      });
      return null;
    }
    let row = {};
    item.c.forEach((cell, idx) => {
      if (headings[idx].toLowerCase() === "email") {
        if (cell.v === null) {
          return null;
        }
      }
      if (headings[idx].toLowerCase() === "role") {
        row[headings[idx].toLowerCase()] =
          cell?.v === "admin" ? "admin" : "user";
      } else if (headings[idx].toLowerCase() === "electric") {
        row[headings[idx].toLowerCase()] = cell?.v;
      } else if (headings[idx].toLowerCase() === "priority") {
        row[headings[idx].toLowerCase()] =
          cell?.v >= 1 && cell?.v <= 5 ? cell?.f : "5";
      } else if (headings[idx].toLowerCase() === "arrival") {
        row[headings[idx].toLowerCase()] =
          cell?.v >= 6 && cell?.v <= 10 ? cell?.f : "9";
      } else {
        row[headings[idx].toLowerCase()] = cell?.v.toString() ?? null;
      }
    });
    return row;
  });
  console.log(data);

  //   // filtering and sorting
  //   data = data.filter((item) => item.Publish === true);
  //   data.sort((a, b) => a.CategoryOrder > b.CategoryOrder);

  //   console.log(data);

  //   // aggregating data by category
  //   data = [...new Set(data.map((item) => item.CategoryOrder))].map(
  //     (categoryIndex) => {
  //       return data.filter((item) => item.CategoryOrder === categoryIndex);
  //     }
  //   );

  //   console.log(data);
  return data;
}

export async function getDataFromSheetWithId(sheetId) {
  const spreadsheetId = "1lipvFbBqi0PhyeNMik6rhgYnBRlg4aJFX6LdjXvwBTQ",
    response = await fetch(
      `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:json`
    ),
    result = await response.text(),
    json = JSON.parse(
      result.replace(
        /.*google.visualization.Query.setResponse\({(.*?)}\);?/s,
        "{$1}"
      )
    );
  const headings = json.table.cols.map((item) => item.label);
  // console.log(headings);

  // data of each row is associated to the headings
  let data = json.table.rows.map((item) => {
    // console.log(item);
    let row = {};
    row.id = randomId();
    item.c.forEach((cell, idx) => {
      row[headings[idx].toLowerCase()] = cell?.v ?? null;
    });
    return row;
  });
  // console.log(data);
  return data;
}
