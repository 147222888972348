import "./platform.styles.scss";
import { useState } from "react";
import BarChartSharpIcon from "@mui/icons-material/BarChartSharp";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import AppRegistrationSharpIcon from "@mui/icons-material/AppRegistrationSharp";
import MapSharpIcon from "@mui/icons-material/MapSharp";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import LazyLoad from "react-lazyload";
import { useInView } from "react-intersection-observer";

function LandingPlatform() {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [animated, setAnimated] = useState(false);

  return (
    <section className="section-platform center" id="platform">
      <div className="platform-container">
        <div
          ref={titleRef}
          className={`platform-text-container ${titleInView ? "slide-in" : ""}`}
        >
          <span className="subheading">הפלטפורמה שלנו</span>
          <h2 className="heading-secondary">
            פלטפורמה למנהלים נוחה ופשוטה לשימוש
          </h2>
          <p className="platform-text">
            בנוסף לאפליקציה, תקבלו פלטפורמה משוכללת למנהלים המאפשר יכולת ניהול
            מיטבית על משאבי החברה והמערכת.
          </p>
        </div>
        <div
          ref={imageRef}
          className={`platform-flex-container flex-row gap-l ${
            inView && animated ? "slide-in" : ""
          }`}
        >
          {/* <div className="platform-img-container"> */}
          {/* center in class name above */}
          {/* <img
              src={require("../assets/webp/platform-screenshot-noshadow-test.webp")}
              alt="platform on ipad + iphone"
            /> */}
          {/* </div> */}
          <LazyLoad once offset={400}>
            <img
              // loading="lazy"
              onLoad={() => setAnimated(true)}
              src={require("../assets/webp/platform-screenshot-noshadow-test.webp")}
              alt="platform on ipad + iphone"
            />
          </LazyLoad>
          <div className="platform-abilities">
            <h3 className="heading-tertiary">חלק מיכולות הפלטפורמה</h3>
            <ul className="list">
              <li className="list-item">
                {/* <ion-icon className="list-icon" name="checkmark-outline"></ion-icon> */}
                <BarChartSharpIcon className="list-icon platform-icon" />
                <span>הצגה של גרפים ואנליזות</span>
              </li>
              <li className="list-item">
                <DescriptionSharpIcon className="list-icon platform-icon" />
                <span>הצגת דיווחים עד כ3 חודשים אחורה</span>
              </li>
              <li className="list-item">
                {/* <EditSharpIcon className="list-icon platform-icon" /> */}
                <ChecklistRtlIcon className="list-icon platform-icon" />
                <span>תצוגה ועריכה של הסטטוס הנוכחי</span>
              </li>
              <li className="list-item">
                <AppRegistrationSharpIcon className="list-icon platform-icon" />
                <span>הוספה והסרה של עובדים, חניות ועמדות עבודה</span>
              </li>
              <li className="list-item">
                <MapSharpIcon className="list-icon platform-icon" />
                <span>העלת מפה לכל קומה</span>
              </li>
              <li className="list-item">
                <SettingsSharpIcon className="list-icon platform-icon" />
                <span>שינוי הגדרות החברה</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingPlatform;
