import "./time-slots.styles.scss";
// import { ReactComponent as Station } from "../assets/webp/ev_station19.svg";
import { useInView } from "react-intersection-observer";
import LazyLoad from "react-lazyload";

function LandingTimeSlots() {
  const [timeSlotsRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-time-slots center" id="time-slots">
      <div
        ref={timeSlotsRef}
        className={`time-slots-container ${inView ? "slide-in" : ""}`}
      >
        <div className="time-slots-text-container">
          <span className="subheading">חלוקה לזמנים</span>
          <h2 className="heading-secondary">ניצול עמדות מיטבי</h2>
          <span className="time-slots-text">
            לא עובדים בתצורה של ימים שלמים? אין בעיה!
            <br /> כאשר עובד יבקש לשריין לעצמו עמדה, הוא יתבקש להכניס את הזמנים
            בהם יהיה נוכח, ויקבל את העמדה רק לזמנים אלו.
            <br /> בדרך זו תוכלו לייעל עוד יותר את עמדות העבודה במשרד ולחסוך
            כסף!
          </span>
        </div>
        <LazyLoad className="lazy-time-slots" once offset={300}>
          <img
            className="time-slots-img"
            src={require("../assets/webp/time_slots_phones.webp")}
            alt="time-slots charging station"
          />
        </LazyLoad>
        {/* <LazyLoad className="lazy-time-slots" once offset={300}>
          <Station className="time-slots-img" />
        </LazyLoad> */}
        {/* <Station2 className="time-slots-img" /> */}
      </div>
    </section>
  );
}

export default LandingTimeSlots;
