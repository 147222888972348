import "./explain.styles.scss";
import { useInView } from "react-intersection-observer";

function LandingExplain() {
  const [explainRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-explain-desks" id="explain">
      <div
        ref={explainRef}
        className={`explain-top-container ${inView ? "slide-in" : ""}`}
      >
        <div className="explain-container">
          <span className="subheading">הסבר קצר</span>
          <h2 className="heading-secondary">
            הסבר על המערכת שלנו לניהול עמדות עבודה
          </h2>
          <span className="explain-text">
            בעזרת המערכת, עובדי החברה יוכלו לנצל את עמדות העבודה במשרד בצורה
            מיטבית. כתוצאה מכך, תוכל החברה לחסוך בעלויות השכרת שטחי המשרד. עובדי
            החברה יהנו משימוש באפליקציה (Android/IOS) נוחה, שבעזרתה יוכלו להקצות
            לעצמם עמדת עבודה בכל יום.
            <br></br>
            מנהלי החברה יקבלו דוחות יומיים וחודשיים וכן גרפים המתארים את ניצול
            עמדות העבודה במשרד.
          </span>
        </div>
        <div className="handshake-img-container">
          <div
            className="explain-img"
            role="img"
            aria-label="man working in workstation"
          ></div>
        </div>
      </div>
    </section>
  );
}

export default LandingExplain;
