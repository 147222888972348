import "./about.styles.scss";
import YoutubeEmbed from "../youtube/youtube.component";
import LazyLoad from "react-lazyload";
import { useInView } from "react-intersection-observer";

function LandingAbout() {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <section className="section-about center" id="about">
      <div className="about-container">
        <div
          ref={titleRef}
          className={`about-text-container ${titleInView ? "slide-in" : ""}`}
        >
          <span className="subheading">קצת עלינו</span>
          <h2 className="heading-secondary">אז, מי אנחנו ואיך נוכל לעזור?</h2>
          <p className="about-text">
            אנחנו בPLAS יצרנו מערכת טכנולוגית אשר תוכל לנהל בצורה מיטבית את
            המשאבים המשותפים בחברה שלכם, על ידי הקצאת חניות ועמדות עבודה בכל יום
            לכל עובד שמתכנן להגיע. במקביל, המערכת עוקבת אחר צרכי העובדים ומבצעת
            אנליזה ודיווחים בזמן אמת שזמינים לממונה על משאבי החברה. בעזרת המערכת
            תוכלו להבין אם החברה אינה מנצלת את משאביה בצורה מיטבית ולעשות
            אופטימיזציה בהתאם.
          </p>
        </div>
        {/* <video width="320" height="240" controls preload>
          <source src="../assets/videos/plas_video_mp4.mp4" type="video/mp4" />
          Your browser does not support the video.
        </video> */}
        {/* <div className="center video-container">
        </div> */}
        <div
          ref={imageRef}
          className={`youtube-lazy ${inView ? "slide-in" : ""}`}
        >
          <LazyLoad once offset={100}>
            {/* <YoutubeEmbed embedId="hVHaT6FaMUU" /> */}
            <YoutubeEmbed embedId="1Wt4kD54wCo" />
          </LazyLoad>
        </div>
        {/* <div className="center about-img-container">
          <img
            src={require("../assets/app/Pixel True Mockup medium cut.webp")}
            alt="platform on ipad + iphone"
          />
        </div> */}
      </div>
    </section>
  );
}

export default LandingAbout;
