import "../../App.css";
import { useEffect } from "react";
import LandingCta from "../cta/cta.component";
import LandingHeroParkings from "../hero-parkings/hero-parkings.component";
import LandingExplain from "../explain/explain.component";
import LandingCommunity from "../community/community.component";
import LandingDp from "../dp/dp.component";
import { Helmet } from "react-helmet";
import LandingEv from "../ev/ev.component";

function LandingPageParkings() {
  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Plas - ניהול חניות משותפות</title>
        <meta
          name="description"
          content="PLAS (פלאס) תסייע לכם לנהל את חניות החברה, תטפל במצוקת החניות הכפולות ותעזור לכם לחסוך כסף"
        />
        <meta property="og:url" content="https://plas.co.il/parkings" />
        <meta name="og:title" content="Plas - ניהול חניות משותפות" />
        <meta
          name="og:description"
          content="PLAS (פלאס) תסייע לכם לנהל את חניות החברה, תטפל במצוקת החניות הכפולות ותעזור לכם לחסוך כסף"
        />
        <meta name="twitter:title" content="Plas - ניהול חניות משותפות" />
        <meta
          name="twitter:description"
          content="PLAS (פלאס) תסייע לכם לנהל את חניות החברה, תטפל במצוקת החניות הכפולות ותעזור לכם לחסוך כסף"
        />
      </Helmet>
      <div className="landing-page-parkings">
        <main>
          {/* HERO SECTION */}
          <LandingHeroParkings />

          {/* EXPLAIN SECTION */}
          <LandingExplain />

          {/* DP SECTION */}
          <LandingDp />

          {/* COMMUNITY SECTION */}
          <LandingCommunity />

          {/* EV SECTION */}
          <LandingEv />

          {/* CTA SECTION */}
          <LandingCta />
        </main>
      </div>
    </div>
  );
}

export default LandingPageParkings;
