import "./phone.styles.scss";
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

function LandingPhone() {
  const [count, setCount] = useState(0);
  const [mousedOver, setMousedOver] = useState(false);
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [step1Ref, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [step2Ref, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [step3Ref, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [step4Ref, inView4] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (!mousedOver) {
      const timer = setInterval(() => {
        setCount((prevCount) => (prevCount + 1) % 4);
      }, 2000);
      return () => clearInterval(timer);
    }
  }, [mousedOver]);

  const handleEnter = () => {
    setMousedOver(true);
  };
  const handleLeave = () => {
    setMousedOver(false);
  };
  return (
    <section className="section-how" id="how">
      <div
        ref={titleRef}
        className={`container title-container ${titleInView ? "slide-in" : ""}`}
      >
        <span className="subheading">איך זה עובד ?</span>
        <h2 className="heading-secondary">
          המסלול שלכם לנוחות וחיסכון בכמה צעדים קטנים
        </h2>
      </div>
      <div className="container grid grid--2-cols grid--center-v">
        {/* <!-- STEP 01 --> */}
        <div
          ref={step1Ref}
          className={`step-text-box step-one ${inView1 ? "slide-in" : ""}`}
        >
          <p className="step-number">01</p>
          <h3 className="heading-tertiary">הרשמה</h3>
          <p className="step-description">
            הרשמה קצרה וקלילה תצרף אותך ואת עובדך למערכת שלנו!
            <br />
            (ניתן גם להתחבר בעזרת גוגל/אפל)
          </p>
        </div>
        <div className={`step-img-box step-one ${inView1 ? "slide-in" : ""}`}>
          <img
            loading="lazy"
            src={require("../assets/app/app-screen-auth.webp")}
            className="step-img"
            alt="IPhone app authentication screen"
          />
        </div>
        {/* <!-- STEP 02 --> */}
        <div
          className={`step-img-box slideshow step-two ${
            inView2 ? "slide-in" : ""
          }`}
        >
          <img
            loading="lazy"
            src={
              count === 1
                ? require("../assets/app/app-screen-get-parking.webp")
                : count === 2
                ? require("../assets/app/app-screen-has-dp-parkings.webp")
                : count === 3
                ? require("../assets/app/app-screen-get-desk.webp")
                : require("../assets/app/app-screen-has-desk.webp")
            }
            className="step-img slideshow-img"
            alt="IPhone app get parking screen"
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
          />
        </div>

        <div
          ref={step2Ref}
          className={`step-text-box step-two ${inView2 ? "slide-in" : ""}`}
        >
          <p className="step-number">02</p>
          <h3 className="heading-tertiary">קבלת החניה/העמדה להיום</h3>
          <p className="step-description">
            על ידי לחיצה פשוטה על כפתור תוקצה לעובד חניה בחניון החברה ו/או עמדת
            עבודה במשרדים ובכך תקל עליו את חיפוש החניה/העמדה
          </p>
        </div>
        {/* <!-- STEP 03 --> */}
        <div
          ref={step3Ref}
          className={`step-text-box step-three ${inView3 ? "slide-in" : ""}`}
        >
          <p className="step-number">03</p>
          <h3 className="heading-tertiary">מציאת החניה/העמדה</h3>
          <p className="step-description">
            לכל קומה בחניון ובמשרד יש מפה שבה ניתן להיעזר על מנת למצוא את המשאב
            המבוקש. אין סיבה להתחיל לחפש ולהסתבך!
          </p>
        </div>

        <div className={`step-img-box step-three ${inView3 ? "slide-in" : ""}`}>
          <img
            loading="lazy"
            src={
              count % 2 === 1
                ? require("../assets/app/app-screen-map-parking.webp")
                : require("../assets/app/app-screen-map-desk.webp")
            }
            className="step-img"
            alt="IPhone app future booking screen"
          />
        </div>
        {/* <!-- STEP 04 --> */}
        <div className={`step-img-box step-four ${inView4 ? "slide-in" : ""}`}>
          <img
            loading="lazy"
            src={require("../assets/app/app-screen-future-parkings.webp")}
            className="step-img"
            alt="IPhone app future booking screen"
          />
        </div>

        <div
          ref={step4Ref}
          className={`step-text-box step-four ${inView4 ? "slide-in" : ""}`}
        >
          <p className="step-number">04</p>
          <h3 className="heading-tertiary">הזמנת חניה/עמדה להמשך השבוע</h3>
          <p className="step-description">
            לא רוצה לשכוח לשריין חניה/עמדה? אין לך כח כל יום להכנס וללחוץ על
            הכפתור? אין בעיה!<br></br> במערכת שלנו ניתן לשמור חניות/עמדות עד
            לשבוע קדימה. רק צריך לסמן באיזה תאריכים לשמור ואנחנו כבר נדאג להודיע
            לך על החניה/העמדה שהוקצת לך בכל יום
          </p>
        </div>
      </div>
    </section>
  );
}

export default LandingPhone;
