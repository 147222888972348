import "./advantages.styles.scss";
import MoneyIcon from "@mui/icons-material/AttachMoney";
import TimeIcon from "@mui/icons-material/AccessTime";
import EasyIcon from "@mui/icons-material/Mood";
import FlipCard from "../../components/flip-card/flip-card.component";
import ComputerIcon from "@mui/icons-material/Computer";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useInView } from "react-intersection-observer";

function LandingAdvantages() {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-advantages" id="advantages">
      <div
        ref={titleRef}
        className={`container center-text title-contanier-advantages ${
          titleInView ? "slide-in" : ""
        }`}
      >
        <span className="subheading">היתרונות שלנו</span>
        <h2 className="heading-secondary">למערכת שלנו יש יתרונות רבים!</h2>
      </div>
      <div
        ref={imageRef}
        className={`container-advantage ${inView ? "slide-in" : ""}`}
      >
        <FlipCard
          id={1}
          frontText={"חוסכת כסף"}
          backText={
            "המערכת שלנו תעזור לכם להבין איפה אתם יכולים לחסוך כסף וכמה!"
          }
          icon={<MoneyIcon className="advantage-icon" />}
        ></FlipCard>
        <FlipCard
          id={2}
          frontText={"חוסכת זמן"}
          backText={
            "בעזרת הקצאת המשאבים, העובדים לא יצטרכו לבזבז זמן בכל בוקר בחיפוש אחר מקומות פנויים"
          }
          icon={<TimeIcon className="advantage-icon" />}
        ></FlipCard>
        {/* <FlipCard
          id={3}
          frontText={"ניהול של חניות כפולות"}
          backText={
            "חניות כפולות? אין בעיה! המערכת עוזרת לנהל את החניות הכפולות על ידי סיפוק פרטי הקשר של השותף לחניה ובכך בקלות ניתן לבקש ממנו להזיז את רכבו"
          }
          icon={
            <div className="double-parking">
              <CarIcon className="advantage-icon" />
              <CarIcon className="advantage-icon" />
            </div>
          }
        ></FlipCard>
        <div className="break"></div>
        <FlipCard
          id={4}
          frontText={"Communnity"}
          backText={
            "השימוש ב Community יסייע להפחתת הסיכויים שעובדי החברה יתקעו ללא חניה "
          }
          icon={<PeopleIcon className="advantage-icon" />}
        ></FlipCard> */}
        <FlipCard
          id={3}
          frontText={"פשוטה לשימוש"}
          backText={"אפליקציה (Android/IOS) נוחה ואינטואיטיבית לשימוש העובדים"}
          icon={<EasyIcon className="advantage-icon" />}
        ></FlipCard>
        <FlipCard
          id={4}
          frontText={"פלטפורמת ניהול"}
          backText={
            "פלטפורמה טכנולוגית להנהלה, עם יכולת הוספה ועריכה של משאבים משותפים וצפיה בדוחות וגרפים"
          }
          icon={<ComputerIcon className="advantage-icon" />}
        ></FlipCard>
        <FlipCard
          id={5}
          frontText={"דוחות"}
          backText={
            "בפלטפורמה ניתן לראות דוחות לפי תאריכים ובתצוגות שונות, המתארות את ניצול המשאבים"
          }
          icon={<TextSnippetIcon className="advantage-icon" />}
        ></FlipCard>

        {/* <div className="advantage-div">
          <div className="icon-div">
            <div className="double-parking">
              <CarIcon className="advantage-icon" />
              <CarIcon className="advantage-icon" />
            </div>
          </div>
          <span className="advantage-text">ניהול של חניות כפולות</span>
        </div>

        <div className="advantage-div">
          <div className="icon-div">
            <TimeIcon className="advantage-icon" />
          </div>
          <span className="advantage-text">חוסך זמן</span>
        </div>

        <div className="advantage-div">
          <div className="icon-div">
            <ParkingIcon className="advantage-icon" />
          </div>
          <span className="advantage-text">חניה שמורה</span>
        </div>

        <div className="advantage-div">
          <div className="icon-div">
            <EasyIcon className="advantage-icon" />
          </div>
          <span className="advantage-text">פשוט לשימוש</span>
        </div> */}
      </div>
    </section>
    // <section className="section-meals" id="meals">
    //   <div className="container center-text">
    //     <span className="subheading">Meals</span>
    //     <h2 className="heading-secondary">
    //       Omnifood AI chooses from 5,000+ recipes
    //     </h2>
    //   </div>

    //   <div className="container grid grid--3-cols margin-bottom-md">
    //     <div className="meal">
    //       <img
    //         src={require("../assets/meals/meal-1.jpg")}
    //         className="meal-img"
    //         alt="Japenese Gyozas"
    //       />
    //       <div className="meal-tags">
    //         <span className="tag tag--vegeterian">
    //           <ion-icon className="tag-icon" name="leaf-outline"></ion-icon>
    //           <span> Vegeterian</span>
    //         </span>
    //       </div>
    //       <div className="meal-content">
    //         <p className="meal-title">Japenese Gyozas</p>
    //         <ul className="meal-attributes">
    //           <li className="meal-attribute">
    //             <ion-icon className="meal-icon" name="flame-outline"></ion-icon>
    //             <span>
    //               <strong>650</strong> calories
    //             </span>
    //           </li>
    //           <li className="meal-attribute">
    //             <ion-icon
    //               className="meal-icon"
    //               name="restaurant-outline"
    //             ></ion-icon>
    //             <span>
    //               {" "}
    //               NutriScore &reg; <strong>74</strong>
    //             </span>
    //           </li>
    //           <li className="meal-attribute">
    //             <ion-icon className="meal-icon" name="star-outline"></ion-icon>
    //             <span>
    //               <strong>4.9</strong> rating (537)
    //             </span>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div className="meal">
    //       <img
    //         src={require("../assets/meals/meal-2.jpg")}
    //         className="meal-img"
    //         alt="Avocado Salad"
    //       />
    //       <div className="meal-tags">
    //         <span className="tag tag--vegan">
    //           <ion-icon className="tag-icon" name="leaf-outline"></ion-icon>
    //           <span> Vegan</span>
    //         </span>
    //         <span className="tag tag--paleo">
    //           <ion-icon
    //             className="tag-icon"
    //             name="accessibility-outline"
    //           ></ion-icon>
    //           <span> Paleo</span>
    //         </span>
    //       </div>
    //       <div className="meal-content">
    //         <p className="meal-title">Avocado Salad</p>
    //         <ul className="meal-attributes">
    //           <li className="meal-attribute">
    //             <ion-icon className="meal-icon" name="flame-outline"></ion-icon>
    //             <span>
    //               <strong>400</strong> calories
    //             </span>
    //           </li>
    //           <li className="meal-attribute">
    //             <ion-icon
    //               className="meal-icon"
    //               name="restaurant-outline"
    //             ></ion-icon>
    //             <span>
    //               {" "}
    //               NutriScore &reg; <strong>92</strong>
    //             </span>
    //           </li>
    //           <li className="meal-attribute">
    //             <ion-icon className="meal-icon" name="star-outline"></ion-icon>
    //             <span>
    //               <strong>4.8</strong> rating (441)
    //             </span>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div className="diets">
    //       <h3 className="heading-tertiary">Works with any diet</h3>
    //       <ul className="list">
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Vegetarian</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Vegan</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Pescatarian</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Gluten-free</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Lactose-free</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Keto</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Paleo</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Low FODMAP</span>
    //         </li>
    //         <li className="list-item">
    //           <ion-icon
    //             className="list-icon"
    //             name="checkmark-outline"
    //           ></ion-icon>
    //           <span>Kid-friendly</span>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>

    //   <div className="container all-recipes">
    //     <a href="#" className="link">
    //       See all recipes &rarr;
    //     </a>
    //   </div>
    // </section>
  );
}

export default LandingAdvantages;
