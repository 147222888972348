import Button from "../../components/button/button.component";
import "./hero-parkings.styles.scss";
import { useInView } from "react-intersection-observer";
// import { ReactComponent as Logo } from "../../landing-page/assets/logo_nobg.svg";
import { ReactComponent as Logo } from "../../assets/Plas-logo-white-big.svg";
import { useEffect } from "react";

function LandingHeroParkings() {
  const [heroRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [enterRef, EnterView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    // if(EnterView){

    // }
    const sectionHero = document.querySelector(".section-hero-parkings");
    const headerEl = document.querySelector(".header");
    const obs = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        if (ent.isIntersecting === false) {
          headerEl.classList.add("sticky");
          sectionHero.classList.add("sticky-margin");
        }

        if (ent.isIntersecting === true) {
          headerEl.classList.remove("sticky");
          sectionHero.classList.remove("sticky-margin");
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-80px",
      }
    );
    if (sectionHero) {
      obs.observe(sectionHero);
    }
  }, [EnterView]);

  // const startObserver = () => {
  //   // Sticky nav
  //   const sectionHero = document.querySelector(".section-hero-parkings");
  //   const headerEl = document.querySelector(".header");
  //   const obs = new IntersectionObserver(
  //     function (entries) {
  //       const ent = entries[0];
  //       if (ent.isIntersecting === false) {
  //         headerEl.classList.add("sticky");
  //         sectionHero.classList.add("sticky-margin");
  //       }

  //       if (ent.isIntersecting === true) {
  //         headerEl.classList.remove("sticky");
  //         sectionHero.classList.remove("sticky-margin");
  //       }
  //     },
  //     {
  //       root: null,
  //       threshold: 0,
  //       rootMargin: "-80px",
  //     }
  //   );
  //   if (sectionHero) {
  //     obs.observe(sectionHero);
  //   }
  // };

  return (
    <section
      className="section-hero-parkings"
      //  onLoad={startObserver}
      ref={enterRef}
    >
      <div ref={heroRef} className={`hero ${inView ? "animate" : ""}`}>
        <div className="hero-text-box">
          {/* <img
            className="hero-logo"
            alt="Plas Logo"
            src={require("../assets/webp/plas_logo_big_no_bg.webp")}
          /> */}
          <Logo className="hero-logo" alt="Plas Logo" />
          <h1 className="heading-primary hero-heading">חניות</h1>
          <p className="hero-description">קצת על ניהול החניות של Plas</p>
          <div className="flex-row hero-button-container">
            <a href="#cta" className="hero-link">
              <Button className="hero-button inverted hero-main-btn">
                הצטרפו אלינו
              </Button>
            </a>
            <a href="#explain" className="hero-link">
              <Button className="hero-button inverted-outlined">
                פרטים נוספים &darr;
              </Button>
            </a>
          </div>
          {/* <div className="delivered-meals">
            <div className="delivered-imgs">
              <img
                src={require("../assets/customers/customer-1.jpg")}
                alt="customer photo"
              />
              <img
                src={require("../assets/customers/customer-2.jpg")}
                alt="customer photo"
              />
              <img
                src={require("../assets/customers/customer-3.jpg")}
                alt="customer photo"
              />
              <img
                src={require("../assets/customers/customer-4.jpg")}
                alt="customer photo"
              />
              <img
                src={require("../assets/customers/customer-5.jpg")}
                alt="customer photo"
              />
              <img
                src={require("../assets/customers/customer-6.jpg")}
                alt="customer photo"
              />
            </div>
            <p className="delivered-text">
              <span>250,000+</span> meals delivered last year!
            </p>
          </div> */}
        </div>
        {/* <div className="hero-img-box">
          <picture>
            <source srcset={require("../assets/hero.webp")} type="image/webp" />
            <source
              srcset={require("../assets/hero-min.png")}
              type="image/png"
            />

            <img
              src={require("../assets/hero-min.png")}
              className="hero-img"
              alt="woman enjoying food, meals in storage container and foos bowls on a table"
            />
          </picture>

        </div> */}
      </div>
    </section>
  );
}

export default LandingHeroParkings;
