import { useState } from "react";
import cn from "classnames";
import { ArrowBack } from "@mui/icons-material";
import MoneyIcon from "@mui/icons-material/AttachMoney";
import TimeIcon from "@mui/icons-material/AccessTime";
import CarIcon from "@mui/icons-material/DirectionsCar";
import ParkingIcon from "@mui/icons-material/LocalParking";
import EasyIcon from "@mui/icons-material/Mood";
import "./flip-card.styles.scss";

function FlipCard({ id, icon, frontText, backText }) {
  return (
    <div className={"flip-card-outer hover"}>
      <div className={"flip-card-inner"}>
        <div className="card front">
          <div className="card-body position-relative d-flex justify-content-center align-items-center">
            <div className="advantage-div">
              <div className="icon-div">{icon}</div>
              <span className="advantage-text card-text-front">
                {frontText}
              </span>
            </div>
          </div>
        </div>
        <div className="card back">
          <div className="card-body d-flex flex-column justify-content-around align-items-center">
            <div className="advantage-div back">
              <span className="advantage-text card-text-back">{backText}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;
