import "./dp.styles.scss";
import { useState } from "react";
import LazyLoad from "react-lazyload";
import { useInView } from "react-intersection-observer";

function LandingDp() {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [animated, setAnimated] = useState(false);
  return (
    <section className="section-dp center" id="dp">
      <div className="dp-container">
        <div
          ref={titleRef}
          className={`dp-text-container ${titleInView ? "slide-in" : ""}`}
        >
          <span className="subheading">חניות כפולות</span>
          <h2 className="heading-secondary">ניהול חניות כפולות</h2>
          <span className="dp-text">
            בעזרת המערכת נוכל לפתור אחת ולתמיד את מצוקת החניות הכפולות (אם
            ישנן). עם PLAS, יוכלו עובדי החברה שחונים בחניות כפולות לראות מי חוסם
            אותם וליצור איתם קשר ישירות מהאפליקציה.
          </span>
        </div>
        <div
          ref={imageRef}
          className={`dp-image-container ${inView ? "slide-in" : ""}`}
        >
          <LazyLoad once offset={400}>
            <img
              onLoad={() => setAnimated(true)}
              src={require("../assets/webp/dp-wide-blue.webp")}
              alt="platform on ipad + iphone"
            />
          </LazyLoad>
        </div>
      </div>
    </section>
  );
}

export default LandingDp;
