import "./cta.styles.scss";
import Button from "../../components/button/button.component";
import { useInView } from "react-intersection-observer";

function LandingCta() {
  const [ctaRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-cta-desks" id="cta">
      <div
        ref={ctaRef}
        className={`container cta-container ${inView ? "slide-in" : ""}`}
      >
        <div className="cta">
          <div className="cta-text-box">
            <h2 className="heading-secondary">הצטרפו אלינו היום!</h2>
            <p className="cta-text">השאירו פרטים כאן ונדאג לחזור אליכם בהקדם</p>

            <form
              className="cta-form"
              name="sign-up"
              method="post"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="sign-up" />
              <div>
                <label htmlFor="full-name">שם מלא</label>
                <input
                  id="full-name"
                  type="text"
                  placeholder="ג׳ון סמית"
                  name="full-name"
                  required
                />
              </div>

              <div>
                <label htmlFor="email">כתובת דוא״ל</label>
                <input
                  id="email"
                  type="email"
                  placeholder="john@example.com"
                  name="email"
                  required
                />
              </div>
              <div>
                <label htmlFor="phone">מספר טלפון</label>
                <input
                  id="phone"
                  type="phone"
                  placeholder="054-4444444"
                  name="phone"
                  required
                />
              </div>
              <div>
                <label htmlFor="company">שם החברה</label>
                <input
                  id="company"
                  type="text"
                  placeholder="plas"
                  name="company"
                  required
                />
              </div>

              <Button className="btn--form" type="submit">
                צרו איתי קשר
              </Button>
            </form>
          </div>
          <div
            className="cta-img-box"
            role="img"
            aria-label="Parking lot full of cars"
          ></div>
        </div>
      </div>
      <img
        className="car-svg"
        src={require("../assets/desk-art.webp")}
        alt="Car in parking clipart"
        style={{
          width: "6.4rem",
          filter:
            "invert(35%) sepia(61%) saturate(285%) hue-rotate(167deg) brightness(97%) contrast(96%)",
        }}
      />
    </section>
  );
}

export default LandingCta;
