import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  createUserDocFromAuth,
  getCurrentUser,
  signInWithGooglePopup,
  signInAuthUserWithEmail,
  createAuthUserWithEmail,
  signOutUser,
  checkEmail,
} from "../../utils/firebase/firebase.utils";
import {
  signInSuccess,
  signInFailed,
  signUpFailed,
  signUpSuccess,
  signOutSuccess,
  signOutFailed,
  googleSignInFailed,
} from "./user.action";
import { USER_ACTION_TYPES } from "./user.types";

export function* getSnapshotFromUserAuth(userAuth, additionalDetails) {
  try {
    const userSnapshot = yield call(
      createUserDocFromAuth,
      userAuth,
      additionalDetails
    );
    console.log(userSnapshot[0].data());
    yield put(
      signInSuccess({
        id: userSnapshot[0].id,
        ...userSnapshot[0].data(),
        time: userSnapshot[1],
      })
    );
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield call(signInWithGooglePopup);
    if (!user) return;
    const check = yield call(checkEmail, user.email);
    if (check === 1) {
      yield put(googleSignInFailed("User is not an admin"));
      // return;
    } else if (check === 0) {
      yield put(googleSignInFailed("User does not exist in our system"));
      // return;
    } else {
      yield call(getSnapshotFromUserAuth, user);
    }
    // if (window.location.href.includes("auth")) window.location.href = "/";
  } catch (error) {
    yield put(googleSignInFailed(error));
  }
}
export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield call(signInAuthUserWithEmail, email, password);
    if (!user) return;

    yield call(getSnapshotFromUserAuth, user);
    // if (window.location.href.includes("auth")) window.location.href = "/";
  } catch (error) {
    yield put(signInFailed(error.code));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield call(getCurrentUser);
    if (!userAuth) return;

    yield call(getSnapshotFromUserAuth, userAuth);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* signUp({ payload: { email, password } }) {
  try {
    const { user } = yield call(createAuthUserWithEmail, email, password);
    yield put(signUpSuccess(user));
  } catch (error) {
    yield put(signUpFailed(error));
  }
}
export function* signInAfterSignUp({ payload: { user, additionalDetails } }) {
  try {
    yield call(getSnapshotFromUserAuth, user, additionalDetails);

    // if (window.location.href.includes("auth")) window.location.href = "/";
  } catch (error) {
    yield put(signUpFailed(error));
  }
}
export function* signOut() {
  try {
    yield call(signOutUser);
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailed(error));
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START, signInWithGoogle);
}
export function* onEmailSignInStart() {
  yield takeLatest(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, signInWithEmail);
}
export function* onSignUpStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_UP_START, signUp);
}
export function* onSignUpSuccess() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* onSignOutStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
}

export function* onCheckUserSession() {
  yield takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* userSaga() {
  yield all([
    call(onCheckUserSession),
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onSignOutStart),
  ]);
}
