import "./ev.styles.scss";
import { ReactComponent as Station } from "../assets/webp/ev_station19.svg";
import { useInView } from "react-intersection-observer";
import LazyLoad from "react-lazyload";

function LandingEv() {
  const [evRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-ev center" id="ev">
      <div ref={evRef} className={`ev-container ${inView ? "slide-in" : ""}`}>
        <div className="ev-text-container">
          <span className="subheading">עמדות טעינה</span>
          <h2 className="heading-secondary">ניהול עמדות טעינה</h2>
          <span className="ev-text">
            העולם מתקדם לרכבים חשמליים ואנחנו איתו!
            <br /> בעזרת המערכת, העובדים בעלי הרכבים החשמליים יוכלו להזמין חניה
            עם עמדת טעינה כאשר הם מגיעים לעבודה.
            <br /> יש יותר ביקוש מהצע? אין בעיה!
            <br /> המערכת תאפשר לשריין זוג עובדים על עמדה ותזכיר להם במהלך היום
            להחליף.
            {/* <br />
            בנוסף, המערכת מספקת מענה לבעיית החניות הכפולות על ידי חלוקת החניות
            הכפולות לעובדים רק כאשר לא נשארו מקומות חניה נוספים, וכאשר לעובד
            יוקצה חניה כפולה הוא יוכל לראות פרטים על שותפו לחניה. */}
          </span>
        </div>
        {/* <img
          className="ev-img"
          src={require("../assets/webp/ev_station.webp")}
          alt="Ev charging station"
        /> */}
        <LazyLoad className="lazy-ev" once offset={300}>
          <Station className="ev-img" />
        </LazyLoad>
        {/* <Station2 className="ev-img" /> */}
      </div>
    </section>
  );
}

export default LandingEv;
