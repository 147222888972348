import "./stress.styles.scss";
import { Parallax } from "react-parallax";
import LazyLoad from "react-lazyload";
import { useInView } from "react-intersection-observer";

function LandingStress() {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [textRef, textInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-stress" id="stress">
      <div
        ref={imageRef}
        className={`handshake-img-container ${inView ? "animate" : ""}`}
      >
        <div
          className="handshake-img"
          role="img"
          aria-label="Photo of people shaking hands"
        ></div>
        <LazyLoad once offset={100}>
          <Parallax
            blur={0}
            bgImage={require("../assets/webp/stress.webp")}
            // bgImage={require("../assets/shaking_hands_3_test.jpg")}
            bgImageAlt="Shaking Hands"
            strength={100}
            className="parralax-stress"
          >
            <div
              className="parralax-stress-image"
              style={{
                backgroundColor: "rgb(173, 205, 236, 0.3)",
              }}
            />
          </Parallax>
        </LazyLoad>
      </div>
      <div className="stress-container">
        <span className={`subheading ${titleInView ? "slide-in" : ""}`}>
          שמירת מקום מראש
        </span>
        <h2
          ref={titleRef}
          className={`heading-secondary ${titleInView ? "slide-in" : ""}`}
        >
          מסירים את הלחץ בהגעה לעבודה
        </h2>
        <p
          ref={textRef}
          className={`stress-text ${textInView ? "slide-in" : ""}`}
        >
          אין יותר מבאס מלהגיע לעבודה בבוקר ולגלות שאין לך עמדת עבודה פנויה.
          בעזרת אפליקציית PLAS כל עובד יוכל להזמין עמדה מראש ולהגיע בבוקר בראש
          שקט למשרד. בנוסף על ידי מפת עמדות עבודה מדויקת, יוכלו עובדי החברה
          למצוא את מקומם בקלות.
        </p>
      </div>
    </section>
  );
}

export default LandingStress;
