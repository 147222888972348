import "./directory.styles.scss";
import DirectoryItem from "../directory-item/directory-item.component";
import { useInView } from "react-intersection-observer";

const Directory = () => {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className={`section-categories gender`} id="directory">
      <div
        ref={titleRef}
        className={`container center-text title-contanier-directory ${
          titleInView ? "slide-in" : ""
        }`}
      >
        <span className="subheading">משאבים</span>
        <h2 className="heading-secondary">
          {/* למערכת שלנו יש יתרונות רבים אשר יטיבו לכם! */}
          מידע נוסף לגבי המשאבים המשותפים
        </h2>
      </div>
      <div
        ref={imageRef}
        className={`categories-container ${inView ? "slide-in" : ""}`}
      >
        {inView
          ? ["parkings", "desks"].map((category) => {
              return (
                <DirectoryItem
                  className={"gender"}
                  key={category}
                  category={category}
                ></DirectoryItem>
              );
            })
          : null}
      </div>
    </section>
  );
};

export default Directory;
