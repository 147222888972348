import "./directory-item.styles.scss";
import { Link, useNavigate } from "react-router-dom";

const DirectoryItem = ({ category }) => {
  const navigate = useNavigate();
  const goToCategory = () => {
    navigate(`/${category.toLowerCase()}`);
  };
  return (
    <div onClick={goToCategory} className={`directory-item-container`}>
      <div className={`background-image ${category}`}></div>

      <a
        href={`/${category.toLowerCase()}`}
        onClick={(event) => {
          event.preventDefault();
        }}
        className="directory-item-body-container"
      >
        <h2 className="directory-item-name">
          {category === "parkings" ? "חניות" : "עמדות עבודה"}
        </h2>
        {/* <p className="shop-now">Shop Now</p> */}
      </a>
    </div>
  );
};

export default DirectoryItem;
