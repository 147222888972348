import "./explain.styles.scss";
import { useInView } from "react-intersection-observer";

function LandingExplain() {
  const [explainRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-explain-parkings" id="explain">
      <div
        ref={explainRef}
        className={`explain-top-container ${inView ? "slide-in" : ""}`}
      >
        <div className="explain-container">
          <span className="subheading">הסבר קצר</span>
          <h2 className="heading-secondary">
            הסבר על המערכת שלנו לניהול חניות משותפות
          </h2>
          <span className="explain-text">
            בעזרת מערכת מתקדמת זו, תוכלו לצמצם את מספר החניות החודשיות עליהן
            החברה משלמת. זאת על ידי יצירת דוחות חניה יומיים וחודשיים המתארים את
            ניצול החניות על ידי עובדי החברה. בנוסף על ידי יצירת קהילה בין החברות
            בבניין ושימוש בחניות הפנויות המשותפות ניתן להוסיף מימד נוסף של
            חיסכון.
            <br /> בעזרת אפליקציה יעודית עובדי החברה יוכלו לשריין לעצמם חניה לכל
            יום (אנחנו נזכיר להם) או להזמין מראש חניה לטווח של עד כשבוע. בכך הם
            יחסכו זמן מיותר בחיפוש אחר חניה.
          </span>
        </div>
        <div className="handshake-img-container">
          <div
            className="explain-img"
            role="img"
            aria-label="man working in workstation"
          ></div>
          {/* <img
          src={require("../assets/webp/office-tall-2.webp")}
          aria-label="man working in workstation"
          className="explain-img"
        /> */}
        </div>
      </div>
    </section>
  );
}

export default LandingExplain;
