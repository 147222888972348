import "./footer.styles.scss";
import { Facebook, LinkedIn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as LogoWide } from "../assets/plas-color-wide.svg";
import { ReactComponent as LogoWide } from "../../assets/Plas-logo-3-horizontal-big.svg";
// import { ReactComponent as LogoWide } from "../../assets/Plas-logo-3-horizontal-white-big.svg";

function LandingFooter() {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="container flex-row gap-l">
        <div className="logo-col">
          <a
            href="#"
            className="footer-logo"
            aria-label="Footer Plas Logo Link"
          >
            {/* <img
              className="logo"
              alt="Plas Logo"
              src={require("../assets/webp/plas_no_bg_dark_wide.webp")}
            /> */}
            <LogoWide className="logo" alt="Plas Logo" />
          </a>
          <div className="flex-column social-copyright">
            <ul className="social-links">
              {/* <li className="social-link">
              <a className="footer-link" href="#">
              <Instagram className="social-icon" />
              </a>
            </li> */}
              <li className="social-link" aria-label="Facebook link">
                <a
                  className="footer-link"
                  aria-label="Facebook link"
                  href="https://www.facebook.com/profile.php?id=61550301147260"
                >
                  <Facebook className="social-icon" />
                </a>
              </li>
              <li className="social-link" aria-label="Linkedin link">
                <a
                  className="footer-link"
                  aria-label="Linkedin link"
                  href="https://www.linkedin.com/company/plas-shared-resource-management"
                >
                  <LinkedIn className="social-icon" />
                </a>
              </li>
              {/* <li className="social-link">
              <a className="footer-link" href="#">
              <Twitter className="social-icon" />
              </a>
            </li> */}
            </ul>

            <p className="copyright">&copy; 2023 Plas כל הזכויות שמורות</p>
          </div>
        </div>
        <div className="address-col">
          <p className="footer-heading">צרו איתנו קשר</p>
          <address className="contacts">
            <p className="address">מורשת, לבונה 294</p>
            <p>
              <a className="footer-link" href="tel:415-201-6370">
                {"058-4966113"}
              </a>
              <br />
              <a className="footer-link" href="mailto:hello@omnifood.com">
                {/* support@plas.co.il */}
                amitai.malka@plas.co.il
              </a>
            </p>
            <p></p>
          </address>
        </div>
        <nav className="nav-col">
          <p className="footer-heading">ניווט</p>
          <div className="footer-nav contacts">
            <a
              className="footer-link"
              href="/home"
              onClick={(event) => {
                event.preventDefault();
                navigate("/home");
              }}
            >
              {"בית"}
            </a>
            <a
              className="footer-link"
              href="/parkings"
              onClick={(event) => {
                event.preventDefault();
                navigate("/parkings");
              }}
            >
              {"חניות"}
            </a>
            <a
              className="footer-link"
              href="/desks"
              onClick={(event) => {
                event.preventDefault();
                navigate("/desks");
              }}
            >
              {"עמדות עבודה"}
            </a>
          </div>
        </nav>
      </div>
    </footer>
  );
}

export default LandingFooter;
