import "./general.scss";
import React from "react";
import { lazy, Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
// import Admin from "./routes/admin/admin.component";
import Spinner from "./components/spinner/spinner.component";
import LandingRoute from "./routes/landing-route/landing-route.component";
const Admin = lazy(() => import("./routes/admin/admin.component"));
// const LandingRoute = lazy(() =>
//   import("./routes/landing-route/landing-route.component")
// );

function App() {
  return (
    <div>
      <Helmet>
        <title>Plas - מערכת חכמה לניהול משאבים משותפים בחברות</title>
        <meta
          name="description"
          content="PLAS (פלאס) היא מערכת המספקת ניהול ואופטימיזציה מיטבית של חניות ועמדות העבודה של הארגונים בבנייני המשרדים"
        />
        <meta property="og:url" content="https://plas.co.il" />
        <meta
          name="twitter:title"
          content="Plas - מערכת חכמה לניהול משאבים משותפים בחברות"
        />
        <meta
          name="twitter:description"
          content="PLAS (פלאס) היא מערכת המספקת ניהול ואופטימיזציה מיטבית של חניות ועמדות העבודה של הארגונים בבנייני המשרדים"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/*" element={<LandingRoute />} />
          <Route path="admin/*" element={<Admin />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
