import "./choose.styles.scss";
import LazyLoad from "react-lazyload";
import { useInView } from "react-intersection-observer";

function LandingChoose() {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-choose center" id="choose">
      <div className="choose-container">
        <div
          ref={titleRef}
          className={`choose-text-container ${titleInView ? "slide-in" : ""}`}
        >
          <span className="subheading">בחירת עמדה</span>
          <h2 className="heading-secondary">העדפה לעמדה מסוימת? אין בעיה!</h2>
          <span className="choose-text">
            לכל עובד יש העדפות שונות למיקום עמדת העבודה במשרד.<br></br> יש
            המעדיפים לשבת ליד מזגן, ישנם שרוצים ליד חלון וכולם מעדיפים לשבת ליד
            החברים.<br></br>
            בנוסף, מנהלים מעדיפים שהעובדים שלהם ישבו בקרבתם.
            <br />
            באפליקציה שלנו כל עובד יוכל לבחור עמדה ספציפית אותה הוא רוצה, ונקצה
            לו אותה במידה והיא פנויה.
          </span>
        </div>
        {/* <video width="320" height="240" controls preload>
          <source src="../assets/videos/plas_video_mp4.mp4" type="video/mp4" />
          Your browser does not support the video.
        </video> */}
        <div
          ref={imageRef}
          className={`center choose-img-container ${inView ? "slide-in" : ""}`}
        >
          <LazyLoad once offset={400}>
            <img
              src={require("../assets/webp/phone-choose.webp")}
              alt="app on iphone showcasing the ability to choose specific workstations"
            />
          </LazyLoad>
        </div>
      </div>
    </section>
  );
}

export default LandingChoose;
