import "./header.styles.scss";
import Button from "../../components/button/button.component";
import { Fragment, useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
// import { ReactComponent as LogoWide } from "../assets/plas_no_bg_white.svg";
// import { ReactComponent as LogoWide } from "../../assets/Plas-logo-3-horizontal-white-big.svg";
import { ReactComponent as LogoWide } from "../../assets/Plas-logo-3-horizontal-white-big.svg";
import Hamburger from "hamburger-react";

function LandingHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [active, setActive] = useState(
    window.location.href.includes("parkings")
      ? "parkings"
      : window.location.href.includes("desks")
      ? "desks"
      : "home"
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.href.includes("#cta")) {
      handleScroll("#cta");
    }
  }, []);

  useEffect(() => {
    setActive(
      window.location.href.includes("parkings")
        ? "parkings"
        : window.location.href.includes("desks")
        ? "desks"
        : "home"
    );
  }, [window.location.href]);

  // const handleToggleNav = () => {
  //   // console.log(document.querySelector(".header").classList);
  //   if (document.querySelector(".header").classList.contains("sticky")) {
  //     setSticky(true);
  //   } else {
  //     setSticky(false);
  //   }

  //   setIsOpen(!isOpen);
  // };

  const handleScroll = (href) => {
    document.querySelector(href).scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const toggleMenu = () => {
    if (document.querySelector(".header").classList.contains("sticky")) {
      setSticky(true);
    } else {
      setSticky(false);
    }
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <Fragment>
      <header
        dir="ltr"
        id="header"
        className={`header ${isOpen ? "nav-open" : ""} ${
          isMobileNavOpen ? "open" : ""
        } ${sticky ? "sticky" : ""}`}
      >
        <div className="nav-container">
          <a
            className="logo-link"
            aria-label="Plas Logo Link"
            href="/"
            onClick={(event) => {
              event.preventDefault();
              // window.scrollTo(0, 0);
              window.scrollTo({
                top: 0,
                behavior: "instant",
              });
              setActive("home");
              navigate(`/`);
              // console.log("scroll");
              // document.body.scrollTo(0, 0);
            }}
          >
            {/* <img
              className="logo"
              alt="Plas Logo"
              src={require("../assets/webp/plas_no_bg_white_wide.webp")}
            /> */}
            {/* <img
              className="navbar-logo"
              alt="Plas Logo"
              src={require("../../assets/Plas-logo-3-horizontal-blue-bg.png")}
            /> */}
            <LogoWide className="logo" alt="Plas Logo" />
          </a>
          <nav className="main-nav">
            <ul className="main-nav-list">
              <li>
                <a
                  className={`main-nav-link ${
                    active === "desks" ? "active" : ""
                  }`}
                  href="/desks"
                  onClick={(event) => {
                    event.preventDefault();
                    if (isOpen) setIsOpen(false);
                    // window.scrollTo(0, 0);
                    // setActive("desks");
                    navigate("/desks");
                    // handleScroll("#hero");
                  }}
                >
                  עמדות עבודה
                </a>
              </li>
              <li>
                <a
                  className={`main-nav-link ${
                    active === "parkings" ? "active" : ""
                  }`}
                  href="/parkings"
                  onClick={(event) => {
                    event.preventDefault();
                    // handleScroll("#about");
                    // setActive("parkings");
                    // window.scrollTo(0, 0);
                    if (isOpen) setIsOpen(false);
                    navigate("/parkings");
                  }}
                >
                  חניות
                </a>
              </li>
              <li>
                <a
                  className={`main-nav-link ${
                    active === "home" ? "active" : ""
                  }`}
                  href="/home"
                  onClick={(event) => {
                    event.preventDefault();
                    // window.scrollTo(0, 0);
                    if (isOpen) setIsOpen(false);
                    // setActive("home");
                    navigate("/");
                    // handleScroll("#about");
                  }}
                >
                  בית
                </a>
              </li>
              {/* <li>
              <a className="main-nav-link" href="#testimonials">
                עדויות
              </a>
            </li> */}
              <li>
                <a className="main-nav-link" href="/admin">
                  התחברות
                </a>
              </li>
              <li>
                <a
                  className="main-nav-link nav-cta"
                  href="#cta"
                  onClick={() => {
                    handleScroll("#cta");
                    if (isOpen) setIsOpen(false);
                  }}
                >
                  <Button className={`nav-cta-btn ${isOpen ? "" : "inverted"}`}>
                    צרו איתי קשר
                  </Button>
                </a>
              </li>
            </ul>
          </nav>
          {/* <button
            className="btn-mobile-nav"
            onClick={handleToggleNav}
            name="Toggle menu button"
          >
            <Menu
              className="icon-mobile-nav"
              name="menu-outline"
              // sx={{ height: "4.8rem", width: "4.8rem", color: "#76abdf" }}
            />
            <Close
              className="icon-mobile-nav"
              name="close-outline"
              // sx={{ height: "4.8rem", width: "4.8rem", color: "#76abdf" }}
            />
          </button> */}
        </div>
        {/* </header> */}

        {/****************************
         *
         * Mobile Navbar
         *
         ******************************/}
        {/* <header
        dir="ltr"
        id="mobile-header"
        className={`mobile-header ${isMobileNavOpen ? "open" : ""} ${
          stickyMobile ? "sticky" : ""
        }`}
      > */}
        <div
          className={`mobile-nav-container ${isMobileNavOpen ? "open" : ""}`}
        >
          <nav className="navbar-mobile">
            <a
              className="logo-link"
              aria-label="Plas Logo Link"
              href="/home"
              onClick={(event) => {
                event.preventDefault();
                // window.scrollTo(0, 0);
                // setActive("home");
                window.scrollTo({
                  top: 0,
                  behavior: "instant",
                });
                navigate(`/`);
                // console.log("scroll");
                // document.body.scrollTo(0, 0);
              }}
            >
              {/* <img
                className="navbar-logo"
                alt="Plas Logo"
                src={require("../../assets/Plas-logo-3-horizontal-blue-bg.png")}
              /> */}
              <LogoWide className="navbar-logo" alt="Plas Logo" />
            </a>
          </nav>
          <div className={`hamburger-container`}>
            <Hamburger
              aria-label="menu-button"
              direction="left"
              duration={0.3}
              rounded
              size={30}
              // color="#777"
              // onClick={toggleMenu}
              toggled={isMobileNavOpen}
              toggle={toggleMenu}
            />
          </div>
          {/* <button className="navbar-burger" onClick={toggleMenu}></button> */}
          <nav className="menu">
            <a
              className="main-nav-link nav-cta"
              href="#cta"
              onClick={() => {
                handleScroll("#cta");
                if (isMobileNavOpen) setIsMobileNavOpen(false);
              }}
            >
              <Button
                className={`nav-cta-btn ${isMobileNavOpen ? "" : "inverted"}`}
              >
                צרו איתי קשר
              </Button>
            </a>
            <a className="main-nav-link" href="/admin">
              התחברות
            </a>
            <a
              className={`main-nav-link ${active === "home" ? "active" : ""}`}
              href="/home"
              onClick={(event) => {
                event.preventDefault();
                // window.scrollTo(0, 0);
                if (isMobileNavOpen) setIsMobileNavOpen(false);
                // setActive("home");
                navigate("/");
                // handleScroll("#about");
              }}
            >
              בית
            </a>
            <a
              className={`main-nav-link ${
                active === "parkings" ? "active" : ""
              }`}
              href="/parkings"
              onClick={(event) => {
                event.preventDefault();
                // handleScroll("#about");
                // setActive("parkings");
                // window.scrollTo(0, 0);
                if (isMobileNavOpen) setIsMobileNavOpen(false);
                navigate("/parkings");
              }}
            >
              חניות
            </a>
            <a
              className={`main-nav-link ${active === "desks" ? "active" : ""}`}
              href="/desks"
              onClick={(event) => {
                event.preventDefault();
                if (isMobileNavOpen) setIsMobileNavOpen(false);
                // window.scrollTo(0, 0);
                // setActive("desks");
                navigate("/desks");
                // handleScroll("#hero");
              }}
            >
              עמדות עבודה
            </a>
          </nav>
        </div>
      </header>
      <Outlet />
    </Fragment>
  );
}

export default LandingHeader;
