import { Routes, Route, Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import "./landing-general.styles.scss";
import "./landing-queries.styles.scss";
import LandingHeader from "../../landing-page/header/header.component";
import LandingPage from "../../landing-page/landing-page/landing-page.components";
import LandingPageDesks from "../../landing-page-desks/landing-page-desks/landing-page-desks.components";
import LandingPageParkings from "../../landing-page-parkings/landing-page-parkings/landing-page-parkings.components";
import LandingFooter from "../../landing-page/footer/footer.component";
// import { CircularProgress } from "@mui/material";
import Spinner from "../../components/spinner/spinner.component";
// const LandingPage = lazy(() =>
//   import("../../landing-page/landing-page/landing-page.components")
// );
// const LandingPageDesks = lazy(() =>
//   import(
//     "../../landing-page-desks/landing-page-desks/landing-page-desks.components"
//   )
// );
// const LandingPageParkings = lazy(() =>
//   import(
//     "../../landing-page-parkings/landing-page-parkings/landing-page-parkings.components"
//   )
// );

const LandingRoute = () => {
  return (
    <div className="landing-route" dir="rtl">
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<LandingHeader />}>
            <Route index element={<LandingPage />} />
            <Route path={"home"} element={<LandingPage />} />
            <Route path={"parkings"} element={<LandingPageParkings />} />
            <Route path={"desks"} element={<LandingPageDesks />} />
          </Route>
        </Routes>
      </Suspense>
      <LandingFooter />
    </div>
  );
};

export default LandingRoute;
