import "../../App.css";
import { useEffect } from "react";
import LandingCta from "../cta/cta.component";
import LandingHeroDesks from "../hero-desks/hero-desks.component";
import LandingExplain from "../explain/explain.component";
import LandingChoose from "../choose/choose.component";
import LandingStress from "../stress/stress.component";
import { Helmet } from "react-helmet";
import LandingTimeSlots from "../time-slots/time-slots.component";

function LandingPageDesks() {
  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Plas - ניהול עמדות עבודה חמות משותפות</title>
        <meta
          name="description"
          content="PLAS (פלאס) תסייע לכם לנהל את עמדות העבודה החמות של החברה, תייעל את ניצול שטח המשרד ותעזור לכם לחסוך כסף"
        />
        <meta property="og:url" content="https://plas.co.il/desks" />
        <meta name="og:title" content="Plas - ניהול עמדות עבודה משותפות" />
        <meta
          name="og:description"
          content="PLAS (פלאס) תסייע לכם לנהל את עמדות העבודה החמות של החברה, תייעל את ניצול שטח המשרד ותעזור לכם לחסוך כסף"
        />
        <meta
          name="twitter:title"
          content="Plas - ניהול עמדות עבודה חמות משותפות"
        />
        <meta
          name="twitter:description"
          content="PLAS (פלאס) תסייע לכם לנהל את עמדות העבודה החמות של החברה, תייעל את ניצול שטח המשרד ותעזור לכם לחסוך כסף"
        />
      </Helmet>
      <div className="landing-page-desks">
        <main>
          {/* HERO SECTION */}
          <LandingHeroDesks />

          {/* EXPLAIN SECTION */}
          <LandingExplain />

          {/* CHOOSE SECTION */}
          <LandingChoose />

          {/* STRESS SECTION */}
          <LandingStress />

          {/* TIME SLOTS SECTION */}
          <LandingTimeSlots />

          {/* CTA SECTION */}
          <LandingCta />
        </main>
      </div>
    </div>
  );
}

export default LandingPageDesks;
