import "./community.styles.scss";
import LazyLoad from "react-lazyload";
import { Parallax, Background } from "react-parallax";
import { useInView } from "react-intersection-observer";

function LandingCommunity() {
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [textRef, textInView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <section className="section-community" id="community">
      <div
        ref={imageRef}
        className={`handshake-img-container ${inView ? "animate" : ""}`}
      >
        <div
          className="handshake-img"
          role="img"
          aria-label="Photo of people shaking hands"
        ></div>
        <LazyLoad once offset={100}>
          <Parallax
            blur={0}
            bgImage={require("../assets/webp/shaking_hands_3_parallax.webp")}
            bgImageAlt="Shaking Hands"
            strength={100}
            className="parralax-community"
          >
            <div
              className="parralax-community-image"
              style={{
                backgroundColor: "rgb(173, 205, 236, 0.3)",
              }}
            />
          </Parallax>
        </LazyLoad>
      </div>
      <div className={`community-container`}>
        <span className={`subheading ${titleInView ? "slide-in" : ""}`}>
          Community
        </span>
        <h2
          ref={titleRef}
          className={`heading-secondary ${titleInView ? "slide-in" : ""}`}
        >
          פרוייקט ״Community״ ואיך הוא יכול לעזור לך?
        </h2>
        <p
          ref={textRef}
          className={`community-text ${textInView ? "slide-in" : ""}`}
        >
          על ידי יצירת קהילה בין החברות בבניין המשתמשות בחניון. תוכלנה החברות
          להשתמש בחניות שאינן שייכות להן ישירות. כאשר לחברות ישארו חניות פנויות,
          חניות אלו יכנסו למאגר של החניות הפנויות בקהילה. כל חברה אשר באותו יום
          תהיה במצוקת חניות תוכל להשתמש בחניות השייכות לקהילה.
          <br />
          איך זה יעזור לכם לחסוך כסף? בעזרת הcommunity נוכל לעזור לכל חברה וחברה
          לדייק בהשכרת כמות החניות הנחוצות לה, ובכך היא תוכל לחסוך כסף נוסף בכל
          חודש.
        </p>
      </div>
    </section>
  );
}

export default LandingCommunity;
